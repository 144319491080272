import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Pagination from "../../../Pagination/Pagination";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Image from "../../../imgData";
import styles from "./sold.module.css";
import SoldCard from "./SoldCard";
import SoldList from "./SoldList";
import { saveView } from "../../../../Redux/slice/activeSlice";
import { BiReset } from "react-icons/bi";
import { Tooltip as ToolTip1 } from "react-tooltip";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const DealerSoldInventory = () => {
  const { id } = useSelector((state) => state.user);
  const view = useSelector((state) => state.active.view);

  const dispatch = useDispatch();

  const location = useLocation();
  const data = location.state || {};

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // For selection of cards
  const isCarRouted = useLocation();

  // const [selectedCards, setselectedCards] = useState([isCarRouted.state ? 1 : null]);
  const [counter, setCounter] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [initialFilters, setInitialFilters] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/listing/get_sold_listing/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (response.status === 200 && data.length > 0) {
        console.log("all the data in sold inventory", data, response);
        const sortedData = data.sort((a, b) => a.id - b.id);
        console.log("all the sortedData in sold inventory", sortedData);
        setTableData(data);
        setInitialFilters(data.map((item) => item.addListing2));
        console.log("______________tableData", sortedData);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts or isDeleted changes
  }, [isDeleted]);
  // for fetch data----------------------------
  useEffect(() => {
    fetchData();
  }, [counter]);

  const handleIncrement = () => {
    setCounter((prevCounter) => prevCounter + 1);
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });

    setSelectedSort(null);
  };
  // --------------------------------------
  useEffect(() => {
    console.log("filterData234:", data);
    if (Object.keys(data).length > 0) {
      // setTableData([...data].sort((a, b) => b.id - a.id));
      // setTableData(data);
      setIsLoading(false);
    }
    // } else {
    //   fetchData();
    // }
  }, [location.state, apiUrl, id]);

  // useEffect(() => {
  //   console.log("Updated tableData:", tableData);
  // }, [tableData, selectedCards]);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  // For Pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewToggle = (newView) => {
    dispatch(saveView(newView));
  };

  // For calculation of DaysIn collumn
  const [daysDifference, setDaysDifference] = useState(0);
  // Calculate the number of days difference
  const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
  };

  const handleRowClick = (index) => {
    // If the clicked row is already selected, deselect it
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      // If the clicked row is not selected, select it
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    }
  };

  // Function to handle export button click
  const tableRef = useRef(null);

  const handleSort = (order) => {
    // const sorted = [...tableData].sort((a, b) => {
    //   const dateA = new Date(a.created_at);
    //   const dateB = new Date(b.created_at);
    //   return order === "oldest" ? dateA - dateB : dateB - dateA;
    // });
    console.log("__________order", order);
    if (!tableData.length) return;

    if (order) {
      const sorted = [...tableData].sort((a, b) => b.id - a.id);
      console.log("__________sorted oldest", sorted);
      // setTableData(sorted);
    } else {
      const sorted = [...tableData].sort((a, b) => a.id - b.id);
      console.log("__________sorted newest", sorted);
      // setTableData(sorted);
    }
  };

  const handleWebsite = (event) => {
    event.stopPropagation();
    alert("Added");
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    alert("Edited");
  };

  const handleDelete = async (event, cardId, index) => {
    event.stopPropagation();

    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this inventory item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    try {
      const listing_id = "listing";
      const response = await fetch(
        `${apiUrl}/listing/delete/${listing_id}/${id}/${cardId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the card");
      }

      // Update the state to reflect the deletion
      // const newTableData = tableData.filter((_, i) => i !== index);
      // setTableData((prevData) => prevData.filter((card) => card.id !== cardId));
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleUpdate = (id, updatedData) => {
    console.log("updatedData>>>>>>>>>>>>>>>>>>>>>>>", updatedData);
    // setTableData((prevData) => prevData.map((item) => item.id === id ? { ...item, addvertising: updatedData } : item));
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    // );
  };

  // useEffect(() => {
  //   // Set a timeout to change the message after 3 seconds
  //   const timer = setTimeout(() => {
  //     console.log("___________tableData in setTimeout", tableData);
  //   }, 2000);

  //   // Cleanup function to clear the timeout if the component unmounts or updates
  //   return () => clearTimeout(timer);
  // }, [selectedCards]);
  // for filter sold data---------------------------------------------------------------*********************-----------------------------

  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });

  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  // const handleSelect = async (selectedStatus) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${selectedStatus}`
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     setTableData(data);
  //     setSelectedSort(selectedStatus);
  //     console.log(data);
  //     // Handle the response data as needed
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleSelect = async (selectedValue, type = "") => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${selectedValue}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Update the selected filter based on the type
      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedValue, // Use computed property name here
        }));
      }
      // else if (type === "make") {
      //   setSelectedFilter((prev) => ({
      //     ...prev,
      //     make: selectedValue,
      //   }));
      // }

      setTableData(data);
      setSelectedSort(selectedValue);
      console.log("selectedFilter", selectedFilter, type, selectedValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // -------------------------------------------------------------------------------------------
  console.log("initialFilters32455", initialFilters);
  // const filterSoldData = initialFilters.addListing2;
  // console.log("filterSoldData32455******",filterSoldData);
  // const filterSoldData =

  // console.log(newData);
  // ------------------------------------------------------------------------------------
  return (
    <Container fluid className="px-4" style={{ marginTop: "-0.5rem" }}>
      <Row className="  d-flex " style={{ justifyContent: "end" }}>
        <Col
          sm={2}
          className=""
          style={{ padding: "0", display: "flex", justifyContent: "end" }}
        >
          {/* Icon in the first column */}
          <div className={styles.toggleView}>
            <img
              src={view === "c" ? Image.cardViewS : Image.cardView}
              alt="card-view"
              width="50%"
              height="100%"
              className={styles.cardView}
              onClick={() => handleViewToggle("c")}
            />
            <img
              src={view === "l" ? Image.listViewS : Image.listView}
              alt="list-view"
              width="50%"
              height="100%"
              className={styles.listView}
              onClick={() => handleViewToggle("l")}
            />
          </div>
        </Col>

        {/* <Col sm={8} className="px-0 d-flex justify-content-center">
          <div className={styles.inventoryInfo}>
            <span className={styles.inventory}>All Inventory:</span>
            <span className={styles.inventoryCount}>{tableData.length}</span>
          </div>
          <div className={styles.inventoryInfo}>
            <span className={styles.inventory}>Published:</span>
            <span className={styles.inventoryCount}>27</span>
          </div>
          <div className={styles.inventoryInfo}>
            <span className={styles.inventory}>Not Published:</span>
            <span className={styles.inventoryCount}>34</span>
          </div>
          <div className={styles.inventoryInfo}>
            <span className={styles.inventory}>Total Leads:</span>
            <span className={styles.inventoryCount}>17</span>
          </div>
        </Col> */}

        <Col
          className="d-flex justify-content-end align-items-center px-0"
          style={{ flex: "0" }}
        >
          {view === "l" && (
            <>
              {/* <Button
                className={`m-2 ${styles.bgBtn} rounded-3 fw-bold ${styles.export}`}
                onClick={handleExportClick}
              >
                Export
              </Button>
              <Button
                className={`m-2 ${styles.bgBtn} rounded-3 fw-bold ${styles.print}`}
                onClick={handlePrintClick}
              >
                Print
              </Button> */}
            </>
          )}

          {/* First dropdown */}
          {/* <div>
            <button
              className={`btn dropdown-toggle me-2 ${styles.drpBtn} ${styles.pageCount}`}
              type="button"
              onClick={() => toggleDropdown("page-count")}
            >
              10
            </button>
            {openDropdown === "page-count" && (
              <ul
                className={`${styles.pageCountList} p-0`}
                ref={dropdownRef}
                style={{ display: openDropdown ? "block" : "none" }}
              >
                <li>
                  <Button className="dropdown-item">1</Button>
                </li>
                <li>
                  <Button className="dropdown-item">2</Button>
                </li>
              </ul>
            )}
          </div> */}

          {/* Second dropdown */}
          {view === "c" && (
            <div>
              {/* <button
                className={`btn dropdown-toggle ${styles.drpBtn} ${styles.sortBy}`}
                type="button"
                onClick={() => toggleDropdown("sort-by")}
              >
                Sort by
              </button> */}
              {openDropdown === "sort-by" && (
                <ul
                  className={`${styles.sortByList} p-0`}
                  ref={dropdownRef}
                  aria-labelledby="dropdownMenuButton2"
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li
                    className={`list-style-none dropdown-item ${styles.sortByItem}`}
                    onClick={() => handleSelect("Asc")}
                  >
                    Oldest
                  </li>
                  <li
                    className={`list-style-none dropdown-item ${styles.sortByItem}`}
                    onClick={() => handleSelect("Desc")}
                  >
                    Newest
                  </li>
                </ul>
              )}
            </div>
          )}
        </Col>
      </Row>

      {view == "l" ? (
        <>
          <Row>
            <Col sm={12} className="px-0" style={{ position: "relative" }}>
              <Container fluid className="px-3">
                <Row>
                  {/* input field start */}
                  <Col
                    sm={11}
                    className="input-group mt-3 mb-4"
                    id="act-search"
                  >
                    <Button
                      className="btn border-0 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ backgroundColor: "#e8a262" }}
                    >
                      All
                    </Button>
                    <ul className="dropdown-menu">
                      <li onClick={() => handleSelect("Price10k")}>
                        <Link className="dropdown-item" to="#">
                          Price &lt; 10k
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Mileage50k")}>
                        <Link className="dropdown-item" to="#">
                          Miles &lt; 50k
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Mileage100k")}>
                        <Link className="dropdown-item" to="#">
                          Miles &lt; 100k
                        </Link>
                      </li>
                    </ul>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Inventory"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                      onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                    />
                    <Button
                      className="btn border-0"
                      type="button"
                      id="button-addon1"
                      style={{ backgroundColor: "#e8a262" }}
                      onClick={handleButtonClick}
                    >
                      <i className="fas fa-search"></i>
                    </Button>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.year === "Year" || selectedFilter.year === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownYear" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      {selectedFilter.year}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownYear"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.year === "" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.year))]
                          .sort((a, b) => a - b)
                          .map((year, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(year, "year")}
                            // onClick={() => handleSelect(year)}
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.year === year
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {year}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.make === "Make" || selectedFilter.make === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownMake" // Added ID for accessibility
                      style={{ backgroundColor: "#e8a262" }}
                    >
                      {selectedFilter.make}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMake"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.make === "" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.make))]
                          .sort()
                          .map((make, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(make, "make")}
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.make === make
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {make}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.models === "Model" || selectedFilter.models === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownModel" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      {selectedFilter.models}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownModel"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.models === "" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.model))]
                          .sort()
                          .map((model, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(model, "models")}
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.models === model
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {model}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.bodyClass === "Body Type" || selectedFilter.bodyClass === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="bodyClass" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      {selectedFilter.bodyClass}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="bodyClass"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.bodyClass === ""
                            ? "selected-item"
                            : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.bodyClass))]
                          .sort()
                          .map((bodyClass, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleSelect(bodyClass, "bodyClass")
                              }
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.bodyClass === bodyClass
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {bodyClass}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedSort === "Desc" || selectedSort === "Asc" ? 'bg-secondary' : ""}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="sortBy" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      Sort BY
                    </Button>
                    <ul className="dropdown-menu"
                      aria-labelledby="sortBy">
                      <li onClick={() => handleSelect("Desc")}>
                        <Link
                          className={`dropdown-item ${selectedSort === "Desc" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Newest
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Asc")}>
                        <Link
                          className={`dropdown-item ${selectedSort === "Asc" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Oldest
                        </Link>
                      </li>
                    </ul>
                    {/* <Button
                        className="btn border-0 btn-eq-wd"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                        onClick={handleIncrement}
                      >
                        <BiReset className="icons-r-flt"/>
                      </Button> */}
                    <Button
                      className="btn border-0 btn-eq-wd"
                      type="button"
                      id="button-addon1"
                      style={{ backgroundColor: "#e8a262" }}
                      onClick={handleIncrement}
                      data-tooltip-id="reset1"
                      data-tooltip-content="Reset"
                      data-event="click"
                    >
                      <BiReset className="icons-r-flt" />
                    </Button>
                    <ToolTip1 id="reset1" place="top" />
                  </Col>
                  {/* input field end */}
                </Row>
              </Container>
            </Col>
          </Row>

          <Row className={`mx-0 rounded bg-body border-2 ${styles.bgTr}`}>
            {/* <BasicDocument /> */}
            <SoldList
              tableData={tableData}
              currentPage={currentPage}
              tableRef={tableRef}
              selectedRows={selectedRows}
              handleRowClick={handleRowClick}
              handleSortByColumn={handleSortByColumn}
            />
            {/* <Col sm={12}>
              <Pagination
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </Col> */}
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col sm={1} className="h-100">
              {/* <div className="select-btn"></div> */}
            </Col>
            <Col sm={11} className="px-0" style={{ position: "relative" }}>
              <Container fluid className="px-3">
                <Row>
                  {/* input field start */}
                  <Col
                    sm={11}
                    className="input-group mt-3 mb-4"
                    id="act-search"
                  >
                    <Button
                      className="btn border-0 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ backgroundColor: "#e8a262" }}
                    >
                      All
                    </Button>
                    <ul className="dropdown-menu">
                      <li onClick={() => handleSelect("Price10k")}>
                        <Link className="dropdown-item" to="#">
                          Price &lt; 10k
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Mileage50k")}>
                        <Link className="dropdown-item" to="#">
                          Miles &lt; 50k
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Mileage100k")}>
                        <Link className="dropdown-item" to="#">
                          Miles &lt; 100k
                        </Link>
                      </li>
                    </ul>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Inventory"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                      onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                    />
                    <Button
                      className="btn border-0"
                      type="button"
                      id="button-addon1"
                      style={{ backgroundColor: "#e8a262" }}
                      onClick={handleButtonClick}
                    >
                      <i className="fas fa-search"></i>
                    </Button>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.year === "Year" || selectedFilter.year === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownYear" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      {selectedFilter.year}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownYear"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.year === "" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.year))]
                          .sort((a, b) => a - b)
                          .map((year, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(year, "year")}
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.year === year
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {year}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.make === "Make" || selectedFilter.make === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownMake" // Added ID for accessibility
                      style={{ backgroundColor: "#e8a262" }}
                    >
                      {selectedFilter.make}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMake"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.make === "" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.make))]
                          .sort()
                          .map((make, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(make, "make")}
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.make === make
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {make}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.models === "Model" || selectedFilter.models === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownModel" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      {selectedFilter.models}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownModel"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.models === "" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.model))]
                          .sort()
                          .map((model, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(model, "models")}
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.models === model
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {model}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.bodyClass === "Body Type" || selectedFilter.bodyClass === null ? "" : 'bg-secondary'}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="bodyClass" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      {selectedFilter.bodyClass}
                    </Button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="bodyClass"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      <li onClick={handleIncrement}>
                        <Link
                          className={`dropdown-item ${selectedFilter.bodyClass === ""
                            ? "selected-item"
                            : ""
                            }`}
                          to="#"
                        >
                          Select None
                        </Link>
                      </li>
                      {initialFilters &&
                        [...new Set(initialFilters.map((car) => car.bodyClass))]
                          .sort()
                          .map((bodyClass, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleSelect(bodyClass, "bodyClass")
                              }
                            >
                              <Link
                                className={`dropdown-item ${selectedFilter.bodyClass === bodyClass
                                  ? "selected-item"
                                  : ""
                                  }`}
                                to="#"
                              >
                                {bodyClass}
                              </Link>
                            </li>
                          ))}
                    </ul>

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedSort === "Desc" || selectedSort === "Asc" ? 'bg-secondary' : ""}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="sortBy" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      Sort BY
                    </Button>
                    <ul className="dropdown-menu"
                      aria-labelledby="sortBy">
                      <li onClick={() => handleSelect("Desc")}>
                        <Link
                          className={`dropdown-item ${selectedSort === "Desc " ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Newest
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Asc")}>
                        <Link
                          className={`dropdown-item ${selectedSort === "Asc" ? "selected-item" : ""
                            }`}
                          to="#"
                        >
                          Oldest
                        </Link>
                      </li>
                    </ul>

                    <Button
                      className="btn border-0 btn-eq-wd"
                      type="button"
                      id="button-addon1"
                      style={{ backgroundColor: "#e8a262" }}
                      onClick={handleIncrement}
                      data-tooltip-id="reset1"
                      data-tooltip-content="Reset"
                      data-event="click"
                    >
                      <BiReset className="icons-r-flt" />
                    </Button>
                    <ToolTip1 id="reset1" place="top" />
                  </Col>
                  {/* input field end */}
                </Row>
              </Container>
            </Col>
          </Row>

          <Row className={`mx-0 rounded bg-body border-2 ${styles.bgTr}`}>
            {tableData &&
              tableData.reverse().map((data, index) => {
                return (
                  <SoldCard
                    data={{
                      ...data,

                      daysDifference: calculateDaysDifference(data.inspection),
                    }}
                    index={index}
                    key={index}
                    setIsDeleted={setIsDeleted}
                    // selectedCards={selectedCards}
                    // handleSelected={handleSelected}
                    handleWebsite={handleWebsite}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    onUpdate={handleUpdate}
                  />
                );
              })}
          </Row>
        </>
      )}
    </Container>
  );
};

export default DealerSoldInventory;
