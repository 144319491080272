import React, { useState } from "react";
import Styles from "./DealerRetail.module.css";
import toast from "react-hot-toast";

const DealerRetailInputs = ({head,type,name,InputValue,handleChange,userMessageError,setUserMessageError }) => {
  const [errorStatus,setErrorStatus] = useState("");
  const formatPhoneNumber = (value) => {
    // Normalize the value (remove non-digit characters)
    const cleaned = ('' + value).replace(/\D/g, '');
    
    // Format as US phone number (XXX) XXX-XXXX or XXX-XXX-XXXX
    const match = cleaned.match(/^(1?)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] ? `+1 (${match[2]}) ${match[3]}-${match[4]}` : `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    
    return value; // Return original value if it doesn't match
  };
  // Example function to call your API
async function callAPI(value) {
  // Create FormData instance
  var fd = new FormData();
  fd.append("email", value); // Use a single key for contact

  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/verify_credential`, {
          method: 'POST',
          body: fd
      });

      const data = await response.text();
      
      console.log('Success:', data);
      if (data === "Phone Number already Exist"){
        toast.error(data);
        setErrorStatus(data);
      }else if (data === "Email already Exist."){
        toast.error(data);
        setErrorStatus(data);
      }
      
  } catch (error) {
      console.error('Error:', error);
  }
}
  const handleInputChange = async (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const valueEmail = e.target.value;
    handleChange(name,e.target.value);
    if (name === 'phoneNumber' && value.length >= 10) {
      try {
          await callAPI(value);
      } catch (error) {
          console.error('API call failed:', error);
          // setUserMessageError("There was an error processing your request.");
      }
  } else if (name === 'email') {
      // Check if the email is valid
      const indexOfAt = valueEmail.indexOf("@");
      const indexOfDot = valueEmail.indexOf(".", indexOfAt);
      if (indexOfAt !== -1 && indexOfDot !== -1) {
          try {
              await callAPI(valueEmail);
          } catch (error) {
              console.error('API call failed:', error);
              // setUserMessageError("There was an error processing your request.");
          }
      } else {
          // setUserMessageError("Please enter a valid email address.");
          console.error("Please enter a valid email address.");
      }
  }
}
  const displayValue = name === 'landlineNumber' || name === 'phoneNumber'
  ? formatPhoneNumber(InputValue)
  : InputValue;
  const maxLength = (head === "Mobile Number" || head === "Land Line") ? 10 : undefined;
  // Determine border color based on the error messages
  const borderColor = 
    (userMessageError === "User with this email already exist." && name === 'email') ||
    (userMessageError === "User with this number already exist." && name === 'phoneNumber') ||
    (errorStatus === "Email already Exist." && name === 'email') ||
    (errorStatus === "Phone Number already Exist" && name === 'phoneNumber')
      ? '2px solid red' 
      : 'initial';
  return (
      <div className={Styles.input_wrapper}>
        <p className={Styles.input_header}>{head}</p>
        <div className={Styles.input_container}>
          <input type="text" value={displayValue} onChange={handleInputChange} style={{fontWeight : "600", fontSize : "x-large",border:`${borderColor}`}}  maxLength={maxLength}/>
          <span>{type}</span>
        </div>
      </div>

  );
};

export default DealerRetailInputs;
