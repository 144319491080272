import React, { useEffect, useRef, useState } from "react";
import Styles from "../Styles/Box_B_Non_Edit.module.css";
import {
  deleteSellPriceLabels,
  updateSellPriceInputs,
  updateSellPriceLabels,
} from "../../../../Redux/slice/taxes&FeesSlice";
import { useDispatch } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const Box_B_Non_Edit = ({ allInputs, editStatus = false }) => {
  console.log("All inputs => ", allInputs);
  const dispatch = useDispatch();
  const [isLabelEditing, setIsLabelEditing] = useState(false);
  //For tracking which element to edit, because isLabelEditing state is editing globally
  const [editingIndex, setEditingIndex] = useState(null);
  const [labelInputValue, setLabelInputValue] = useState("");
  const inputRef = useRef(null);
  const handlelabelEdit = (index, boolVal, value) => {
    console.log("Value to be sent in the input field", value);
    setLabelInputValue(value);
    setIsLabelEditing(boolVal);
    setEditingIndex(index);
  };

  const handleLabelInputChange = (index, value) => {
    setLabelInputValue(value);
    dispatch(updateSellPriceLabels({ index, value }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsLabelEditing(false);
        setLabelInputValue("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsLabelEditing(false);
      setLabelInputValue("");
    }
  };

  const handleDeleteLabel = (index) => {
    dispatch(deleteSellPriceLabels({ index }));
  };

  useEffect(() => {
    if (editStatus && isLabelEditing) {
      inputRef.current.focus();
    }
  }, [editStatus, isLabelEditing, editingIndex]);
  //for tracking the width of the screen
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  console.log("isSmallScreen  => ", isSmallScreen);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize once to set the initial state

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("allInputs => ", allInputs);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        {allInputs &&
          allInputs?.map((item, index) => (
            <div key={item.id} className={Styles.inputContainer}>
              {editStatus && isLabelEditing && editingIndex === index ? (
                <input
                  type="text"
                  className={Styles.headerInputLabel}
                  ref={inputRef}
                  value={labelInputValue}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    const { value } = e.target;
                    handleLabelInputChange(index, value);
                  }}
                />
              ) : (
                <p
                  className={Styles.inputLabelContainer}
                  style={{ width: "40%" }}
                >
                  {/* <span>{item.label}</span>{" "} */}

                  {isSmallScreen ? (
                    <>
                      <span
                        data-tooltip-id={`tooltip-${item.id}`}
                        data-tooltip-content={item.label}
                        data-event="click"
                      >
                        {item.label ? item.label.split(" ")[0] : null}...
                      </span>
                      <Tooltip id={`tooltip-${item.id}`} place="right" />
                    </>
                  ) : (
                    <span>{item.label}</span>
                  )}

                  {editStatus && !isLabelEditing && (
                    <>
                      <span
                        onClick={() => handlelabelEdit(index, true, item.label)}
                      >
                        <MdModeEditOutline />
                      </span>
                      <span onClick={() => handleDeleteLabel(index)}>
                        <AiTwotoneDelete />
                      </span>
                    </>
                  )}
                </p>
              )}
              {/* <div className="d-flex align-items-center justify-content-end"> */}
              <span style={{ fontWeight: "600" }}>$</span>
              <input
                type="text"
                value={item.InputValue}
                placeholder="0"
                className={Styles.inputField}
                // onChange={(e) => {
                //   const { value } = e.target;
                //   dispatch(updateSellPriceInputs({ index, value }));
                // }}
                onChange={(e) => {
                  const { value } = e.target;
                  const numericValue = value.replace(/[^\d\.]/g, ""); // remove non-numeric characters
                  dispatch(
                    updateSellPriceInputs({ index, value: numericValue })
                  );
                }}
              />
              {/* </div> */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Box_B_Non_Edit;
