import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../imgData";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import { setActiveTab } from "../../../Redux/slice/sellSlice";
import "./components/SaleType.css";

const SaleType = () => {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(null);
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  const activeTabFromRedux = useSelector((state) => state.sell.activeTab);
  const stateData = retailFromRedux.txtTitleType;
  console.log("txtTitleType", stateData);
  const handleBtnTxt = (txt) => {
    dispatch(setRetailData({ txtTitleType: txt }));
    dispatch(setActiveTab("Buyer"));
  };

  const saleTypes = [
    { id: "Retail", label: "Retail/Cash", image: Image.dealer_retail },
    { id: "Finance/BHPH", label: "Finance/BHPH", image: Image.dealer_finance },
    { id: "Out of State", label: "Out of State", image: Image.dealer_oos },
    { id: "Whole Sale", label: "Whole Sale", image: Image.sidebarHome },
    {
      id: "Rent & Lease",
      label: "Rent & Lease",
      image: Image.dealer_rent_lease,
    },
  ];
  useEffect(() => {
    console.log("stateData => ", stateData);
    if (activeTabFromRedux === "Sale Type" && stateData) {
      setSelectedType(stateData);
    }
  }, [activeTabFromRedux, stateData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: ' 3rem 2rem'
        // marginTop: "10%",
      }}
    >
      {saleTypes.map((type) => (
        <div
          key={type.id}
          className="sale_type_data"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            gap: "2rem",
            cursor: "pointer",
            paddingLeft: "11px",
            justifyContent: "center",
          }}
          onClick={() => {
            // setSelectedType(type.label);
            handleBtnTxt(type.label);
          }}
        >
          <div>
            <input
              type="radio"
              value={type.label}
              id={type.id}
              checked={stateData === type.label}
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
            />
          </div>
          <div className={`d-flex justify-content-evenly`}>
            <img
              src={type.image}
              style={{ height: "40px", width: "40px" }}
              alt={type.label}
            />
            {/* </div>
          <div> */}
            <label
              htmlFor={type.id}
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                color: "white",
                width: "226px",
                marginLeft: '5%',
              }}
            >
              {type.label}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SaleType;
