import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FaApple, FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { IoArrowDownCircle, IoArrowUpCircle } from "react-icons/io5";
import { IoLogoAndroid } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { FaCar } from "react-icons/fa";
import Image from "../imgData";
import ParentDialogue from "../UserAuthDialogue/Pages/ParentDialogue";
import { useSelector, useDispatch } from "react-redux";
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from "react-icons/io";
import { FaUserLarge } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import {
  logoutUser,
  resetAllSidebarCounts,
  saveUser,
} from "../../Redux/slice/userSlice";
import { FaMicrophone } from "react-icons/fa";
import "../navbars/navbar.css";
import { FaUser } from "react-icons/fa";
import { FaCarAlt } from "react-icons/fa";
import axios from "axios";
import UserRoutes from "./UserRoutes";
import { TiThMenu } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import DealerSideBar from "../DealerSection/DealerSideBar/DealerSideBar";
import SideBar from "../dashboard/SideBar";
import { IoMdNotifications } from "react-icons/io";
import { setRetailData } from "../../Redux/slice/taxes&FeesSlice";

import { incrementCounter } from "../../Redux/slice/activeSlice";
import {
  setActiveTab,
  resetCoBuyerSellData,
  resetBuyerSellData,
  resetSellData,
} from "../../Redux/slice/sellSlice";

import AddlistingData from "../DealerSection/DealerInventory/DealerBalanceInventory/AddlistingData";
import SellData from "../DealerSection/DealerInventory/activeInventory/SellData";
import SellDataNav from "../DealerSection/DealerInventory/activeInventory/SellDataNav";
import DealerAddInventoryNav from "../DealerSection/DealerInventory/DealerAddInventory/DealerAddInventoryNav";
import LoanApplication from "../DealerSection/LoanApplication/LoanApplication";
const imgUrl = process.env.REACT_APP_IMG_URL;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginDialogueBox, setShowLoginDialogueBox] = useState(false);
  const [showUserActions, setShowUserActions] = useState(false);
  const [showNavMenuA, setShowNavMenuA] = useState(false);
  const { sideBarItemName } = useSelector((state) => state.user);
  const [showUserActionsMobile, setShowNavMenuMobile] = useState(false);
  const [showSearchDropDownItems, setShowSearchDropDownItems] = useState(false);
  const [showUserActionsAfterLogin, setShowUserActionsAfterLogin] =
    useState(false);
  // console.log(showLoginDialogueBox);
  const userStoreData = useSelector((state) => state.user);

  const dispatch = useDispatch();
  console.log("userStoreData", userStoreData);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const { sideBarOrder } = useSelector((state) => state.user);
  const { id } = useSelector((state) => state.user);
  // console.log("sideBarOrder in navbar component ", sideBarOrder);

  const handleShowLoginDialogueBox = () => {
    setShowLoginDialogueBox(!showLoginDialogueBox);
    // console.log(showLoginDialogueBox);
  };

  //In productListing section if user opt for sign in as customer then open customer login modal
  const { showLoginDialogue } = userStoreData;
  useEffect(() => {
    if (showLoginDialogue) {
      setShowLoginDialogueBox(true);
    }
  }, [showLoginDialogue, showLoginDialogueBox]);

  useEffect(() => {
    if (userStoreData && userStoreData.id && userStoreData.email) {
      setShowLoginDialogueBox(false);
    }
  }, [userStoreData]);
  const handleViewUserActions = () => {
    setShowUserActions(!showUserActions);
  };

  const apiData =
    sideBarOrder &&
    sideBarOrder?.map((item) => ({
      tabId: item.id,
      tabCount: item.count,
      userId: id,
    }));

  const handleLogout = async () => {
    dispatch(logoutUser());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/save`,
        apiData
      );
      if (res.status === 200) {
        dispatch(resetAllSidebarCounts());
        localStorage.removeItem("user_sidebar_activity");
        dispatch(resetSellData());
      }
      // console.log(
      //   "response from the API while sending user activity to the backend related to sidebar",
      //   res
      // );
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogoutTablet = async () => {
    dispatch(logoutUser());
    setShowUserActionsAfterLogin(false);
    dispatch(logoutUser());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/save`,
        apiData
      );
      // console.log(
      //   "response from the API while sending user activity to the backend related to sidebar",
      //   res
      // );
      if (res.status === 200) {
        dispatch(resetAllSidebarCounts());
        dispatch(resetSellData());
        localStorage.removeItem("user_sidebar_activity");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleShowNavMenuA = () => {
    setShowNavMenuA(!showNavMenuA);
  };

  const handeMobileDesign = () => {
    setShowNavMenuMobile(!showUserActionsMobile);
  };

  const [selectedOption, setSelectedOption] = useState("All");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const HandleShowSearchDropDownItems = () => {
    setShowSearchDropDownItems(!showSearchDropDownItems);
  };

  const handleSelectSearchDropDown = (option) => {
    setSelectedOption(option);
    setShowSearchDropDownItems(false);
  };

  const handleShowUserActionAfterLogion = () => {
    setShowUserActionsAfterLogin(!showUserActionsAfterLogin);
  };

  // useEffect to get data from the url
  //   useEffect(() => {
  //     // Function to get a specific query parameter
  //     const getQueryParam = (param) => {
  //       const searchParams = new URLSearchParams(window.location.search);
  //       return searchParams.get(param);
  //     };

  //     // Extract email and countryCode
  //     const emailParam = getQueryParam('email');
  //     const countryCodeParam = getQueryParam('countryCode');
  // console.log('emailParam' , emailParam , "countryCodeParam",countryCodeParam)
  //     // Open the box if condition true
  //     if(emailParam && countryCodeParam) setShowLoginDialogueBox(true)
  //   }, []);

  //Now Show the dealer sidebar on the Homepage
  const [showDealerSideBar, setShowDealerSideBar] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };
  const [isHoveredLogo, setIsHoveredLogo] = useState(false);
  const handleSidebarHoverLogo = (hovered) => {
    setIsHoveredLogo(hovered);
  };
  const isPermanentlyExpanded = true;

  //handling the automatic closing and opening of sidebar
  console.log("userStoreData.isSideBarOpen => ", userStoreData.isSideBarOpen);
  // useEffect(() => {
  //   if (userStoreData.isSideBarOpen) {
  //     setShowDealerSideBar(true);
  //   } else {
  //     setShowDealerSideBar(false);
  //   }
  // }, [userStoreData]);
  //Customer sidebar starts here
  const [navbarText, setNavbarText] = useState("");
  const handleNavbarTextChange = (text) => {
    setNavbarText(text);
  };
  const isOpen2 = true;
  const [isPermanentlyExpanded2, setIsPermanentlyExpanded] = useState(false);
  console.log("isOpen", isOpen2);
  const toggleSidebar = () => {
    setIsOpen(!isOpen2);
    setIsPermanentlyExpanded(!isPermanentlyExpanded2);
  };
  const [showNoty, setShowNoty] = useState(false);

  const selectedVehicle = useSelector((state) => state.sell.selectedVehicle);
  const showSellNav = useSelector(
    (state) => state.taxesAndFees.dealerRetail.showSellNav
  );

  const [showModalSell, setShowModalSell] = useState(false);
  const [showModalSellData, setShowModalSellData] = useState(false);
  const [showModalAddInventory, setShowModalAddInventory] = useState(false);
  const [showModalLoanApplication, setShowModalLoanApplication] =
    useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [IsThisAddCost, setIsThisAddCost] = useState(false);
  const [showAddList, setShowAddList] = useState(false);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleModalCloseSell = () => {
    // dispatch(resetTexasDynamicPriceFees());
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        totalFeesAndothers: 0,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: null,
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: getCurrentDate(),
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );
    dispatch(incrementCounter());
    setShowModalSell(false);
    setShowModalSellData(false);
    console.log("Vehicle List in close");
  };

  const handleClickSellData = () => {
    console.log("Vehicle List in open");
    // dispatch(saveUser({ isSideBarOpen: false }));
    dispatch(setActiveTab("Vehicle List"));
    dispatch(incrementCounter());
    setShowModalSellData(true);
  };
  const handleAddInventory = () => {
    setShowModalAddInventory(true);
  };

  const handleAddInventoryClose = () => {
    setShowModalAddInventory(false);
  };
  // -----------------------------------------------------Loan Application Forms--------------------------------------
  const handleLoanApplication = () => {
    setShowModalLoanApplication(true);
  };

  const handleLoanApplicationClose = () => {
    setShowModalLoanApplication(false);
  };
  // -----------------------------------------------------------------------------------------------------------------
  const handleClickSell = () => {
    console.log("Vehicle List in open");
    // dispatch(saveUser({ isSideBarOpen: false }));
    dispatch(setActiveTab("Vehicle List"));
    dispatch(incrementCounter());
    // dispatch(setActiveTabIndex(0));
    setShowModalSell(true);
    setIsThisAddCost(true);
  };

  const handleModalOpenAdd = () => {
    setShowAddList(true);
  };

  const handleModalCloseAdd = () => {
    dispatch(incrementCounter());
    dispatch(setActiveTab("Vehicle List"));
    setShowAddList(false);
  };

  const handleCloseImgModal = () => {
    // setShowModalAddlisting(false);
    setShowModalImage(false);
  };

  return (
    <>
      {/* First Navbar */}
      <header
      // style={{ display: "flex", flexDirection: "row" }}
      >
        {/* {userStoreData &&
        userStoreData.id !== 0 &&
        userStoreData.email &&
        shouldShow &&
        // userStoreData.loginAccess &&
        // userStoreData.dealerApproval &&
        // userStoreData.dealerData &&
        userStoreData.roles === "Dealer" ? (
          <div
            className={`dealer_sidebar_home_Parent ${
              showDealerSideBar ? "ShowSidebar" : "HideSidebar"
            }`}
            style={{ position: "relative" }}
          >
            <DealerSideBar
              isPermanentlyExpanded={isPermanentlyExpanded}
              onHoverChange={handleSidebarHover}
            />{" "}
          </div>
        ) : null} */}
        <div className="parent_nav_a">
          {/* {userStoreData &&
          userStoreData.id !== 0 &&
          userStoreData.email &&
          userStoreData.loginAccess &&
          userStoreData.dealerApproval &&
          userStoreData.dealerData &&
          userStoreData.roles === "User" ? (
            <div
              className={`dealer_sidebar_home_Parent ${
                showDealerSideBar ? "ShowSidebar" : "HideSidebar"
              }`}
            >
              <SideBar
                onButtonClick={handleNavbarTextChange}
                isOpen={isOpen2}
                toggleSidebar={toggleSidebar}
                isPermanentlyExpanded={isPermanentlyExpanded2}
                setIsOpen={setIsOpen}
                setIsPermanentlyExpanded={setIsPermanentlyExpanded}
              />
            </div>
          ) : null} */}

          {/* Mobile Apps */}
          <div className="mobile_apps">
            <div className="dac_logo_for_tablet">
              {userStoreData &&
              userStoreData.id !== 0 &&
              userStoreData.email ? (
                // userStoreData.loginAccess &&
                // userStoreData.dealerApproval &&
                // userStoreData.dealerData &&
                <div className="dealer_sidebar_home_btn_container">
                  <span
                    onClick={() => {
                      dispatch(saveUser({ isSideBarOpen: !showDealerSideBar }));
                      setShowDealerSideBar(!showDealerSideBar);
                    }}
                    style={{
                      cursor: "pointer",
                      padding: "0.4rem",
                      paddingTop: "0.1rem",
                      borderRadius: "50%",
                      backgroundColor: showDealerSideBar
                        ? "lightgrey"
                        : "white",
                    }}
                    className="dealer_sidebar_home_btn"
                  >
                    <TiThMenu />
                  </span>
                </div>
              ) : null}

              <Link to="/">
                <span>DEALERS</span>
                <span>AUTO</span>
                <span>CENTER</span>
                {/* <img src={Image.logoDac} alt="DAC_logo" height={60} width={70} /> */}
              </Link>
            </div>
          </div>
          {/* Second and third part of Navbar */}
          <div className="ham_user">
            {/* Hamburger Menus */}
            <div className="ham_items">
              <span
                // onClick={handleShowNavMenuA}
                onClick={() => {
                  dispatch(saveUser({ isSideBarOpen: !showDealerSideBar }));
                  setShowDealerSideBar(!showDealerSideBar);
                }}
              >
                <IoMenu />
              </span>
              {showNavMenuA ? (
                <div className="absolute_nav_items_a">
                  <span>For Sale</span>
                  <span>
                    {" "}
                    <Link to="/product">Products</Link>
                  </span>

                  <span>About DAC </span>
                  <p className="absolute_app_section">
                    IOS APP
                    <FaApple />
                  </p>
                  <p className="absolute_app_section">
                    Android APP
                    <IoLogoAndroid />
                  </p>
                  <p className="absolute_app_section">
                    Dealers
                    <FaUserLarge />
                  </p>
                  <p className="absolute_app_section">
                    Sell My Car
                    <FaCarAlt />
                  </p>
                </div>
              ) : null}
            </div>
            <p className="tab_nav_items">
              <div className="wrapper_search">
                {/* input field */}
                <p className="input_search_field">
                  <input type="text" placeholder="Search by Vehicle" />
                  {/* <span className="search_icon_container">
                    <FaSearch />
                  </span> */}
                  {/* Microphone icon*/}
                  <span className="microphone_container">
                    <FaMicrophone />
                  </span>
                </p>
              </div>
            </p>
            {/* Show Logo Icon on Mobile Screen */}
            <div className="logo_for_mobile">
              <Link to="/">
                {/* <img
                  src={Image.logoDac}
                  alt="logo for mobile"
                  height={50}
                  width={50}
                /> */}
                <span style={{ marginRight: "0.3rem" }}>DEALERS</span>
                <span style={{ marginRight: "0.3rem" }}>AUTO</span>
                <span>CENTER</span>
              </Link>
            </div>
            {/* User Section for Mobile*/}
            <div className="user_section_for_mobile">
              {userStoreData.email === "" ? (
                <div
                  onClick={handleShowLoginDialogueBox}
                  style={{ cursor: "pointer" }}
                  className="user_action_mobile"
                >
                  <span className="sign_in_sign_up_button_mobile">
                    <img
                      src={Image.loginb}
                      onClick={handleShowLoginDialogueBox}
                      style={{ height: "25px", width: "25px" }}
                      alt="Login"
                    />
                  </span>
                </div>
              ) : (
                <div className="user_action_mobile">
                  <img
                    // src={
                    //   userStoreData.profile_picture
                    //     ? `${imgUrl}/${userStoreData.id}/${userStoreData.profile_picture}`
                    //     : Image.avatar_img
                    // }
                    src={Image.logouta}
                    alt="avatar image"
                    height={25}
                    width={25}
                    onClick={handleLogoutTablet}
                  />
                  {/* <span onClick={handeMobileDesign}> Mr. Emran</span> */}
                  {showUserActionsMobile ? (
                    <div className="user_action_mobile_items">
                      <p>
                        {userStoreData.firstName
                          ? `${userStoreData.firstName}`
                          : "User"}
                      </p>
                      <p>
                        <Link to="/myprofile/dashboard">Dashboard</Link>
                      </p>
                      <p>
                        <Link to="/myprofile">Profile</Link>
                      </p>
                      <p>
                        <Link to="/myprofile/addlist">Add Inventory</Link>
                      </p>
                      <p>
                        My Favourite <FaHeart />
                      </p>
                      <p>Messages</p>
                      <p>Offers</p>
                      <p onClick={handleLogout} style={{ cursor: "pointer" }}>
                        Logout
                      </p>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            {showLoginDialogueBox ? (
              <div
                className="childContainer"
                style={{
                  transform: `${
                    showLoginDialogueBox ? "translatex(0)" : " translatex(-70%)"
                  }`,
                  transition: "2s ease-in transform",
                }}
              >
                <ParentDialogue
                  setShowLoginDialogueBox={setShowLoginDialogueBox}
                  showLoginDialogueBox={showLoginDialogueBox}
                />
              </div>
            ) : null}
            {/* User Section for tablet*/}
            <div className="user_section_for_tablet">
              {userStoreData.email === "" ? (
                <div className="d-flex align-items-center">
                  {" "}
                  <div className="user_div_for_tablet">
                    {userStoreData.email === "" ? (
                      <div>
                        <form className=" dealer_section_for_big_laptop dealer_navbar_extras">
                          <Link
                            className="btns btn-outline-dark"
                            to="/dealer_facilities_home"
                            type="button"
                            style={{
                              color: "#ffffff",
                              fontWeight: "600",
                              color: "#c57a40",
                              // backgroundColor : "#D7A37B"
                            }}
                          >
                            <i
                              className="fa-regular fa-user"
                              style={{ color: "#c57a40" }}
                            ></i>{" "}
                            <span className="" style={{ color: "#c57a40" }}>
                              Dealers
                            </span>
                          </Link>
                          <button
                            className="btns btn-outline-dark btn-margin text-light "
                            type="button"
                            style={{
                              color: "#ffffff",
                              fontWeight: "600",
                              //  backgroundColor : "#D7A37B",
                              color: "white",
                            }}
                          >
                            <i
                              className="fa-solid fa-car-rear "
                              style={{ color: "#c57a40" }}
                            ></i>{" "}
                            <span style={{ color: "#c57a40" }}>
                              Sell My Car
                            </span>
                          </button>
                        </form>
                        <div className="dealer_section_for_tablet">
                          <span>
                            <FaUser />
                          </span>
                          <span>
                            <FaCarAlt />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="user_icon_name_tablet">
                        <img
                        // src={
                        //   userStoreData.profile_picture
                        //     ? Image.power_button
                        //     : // ? `${imgUrl}/${userStoreData.id}/${userStoreData.profile_picture}`
                        //       Image.power_button
                        // }
                        src={Image.logouta}
                        onMouseEnter={() => setIsHoveredLogo(true)}
                        onMouseLeave={() => setIsHoveredLogo(false)}
                        alt="Avatar Image for Tablet"
                        height={30}
                        width={30}
                        // onClick={handleShowUserActionAfterLogion}
                        onClick={handleLogoutTablet}
                        className="avatar_image_icon"
                      />
                        {/* <p onClick={handleShowUserActionAfterLogion}>
                          {userStoreData.firstName
                            ? `${userStoreData.firstName}`
                            : "User"}
                        </p> */}
                      </div>
                    )}
                  </div>
                  <p
                    onClick={handleShowLoginDialogueBox}
                    style={{
                      cursor: "pointer",
                      fontWeight: "600",
                      // backgroundColor: showLoginDialogueBox ? "gray" : "",
                      // borderRadius:"50%",
                      position: "relative",
                      display: "inline-block",
                    }}
                    className="sign_in_signup_tablet"
                  >
                    <img
                      src={Image.loginb}
                      style={{ height: "35px", width: "35px" }}
                      onMouseEnter={() => setIsHoveredLogo(true)}
                      onMouseLeave={() => setIsHoveredLogo(false)}
                      alt="Login"
                    />
                    {isHoveredLogo && (
                      <div
                        style={{
                          position: "absolute",
                          // bottom: "30px",
                          // left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          color: "white",
                          padding: "5px",
                          borderRadius: "4px",
                          whiteSpace: "nowrap",
                          zIndex: 1,
                        }}
                      >
                        Sign In
                      </div>
                    )}
                  </p>
                </div>
              ) : (
                <div className="user_related_action_tablet">
                  {userStoreData.roles === "User" ? (
                    <>
                      {" "}
                      <img
                        src={Image.sidebarMyFavrites}
                        alt="Favourite Icon"
                        height={38}
                        width={38}
                        title="My Favourite"
                        style={{ marginTop: "-16px", marginRight: "10px" }}
                      />
                      <p>My Favorite | 12</p>
                      {/* <img
                        src={Image.messanger}
                        alt="Messanger Icon"
                        height={30}
                        width={30}
                        title="Messages"
                      /> */}
                      <p>Messages | 12</p>
                      <img
                        src={Image.offers}
                        alt="Offers Icon"
                        height={30}
                        width={30}
                        title="Offers"
                        style={{ marginTop: "-16px", marginRight: "10px" }}
                      />
                      <p>Offers | 12</p>
                    </>
                  ) : (
                    <div style={{ position: "relative" }} className="dmsBtnContainer">
                      {" "}
                      <div
                        className="container-nav"
                        style={{ display: "flex" }}
                      >
                        <span style={{ width: "150px", marginTop: "13px" }}>
                          {sideBarItemName}
                        </span>

                        {/* <button
                          type="button"
                          className="border-0"
                          onClick={handleLoanApplication}
                          style={{ width: "218px" }}
                        >
                          <u>Loan Application Form</u>
                        </button> */}
                        <button
                          type="button"
                          className="border-0"
                          onClick={handleAddInventory}
                        >
                          <u>Add Inventory</u>
                        </button>
                        {/* <Link to="/dealer/dashboard"></Link> */}
                        {/* <Link to="/dealer/dashboard"></Link> */}
                        {/* <button
                          type="button"
                          className="border-0"
                          onClick={handleClickSellData}
                        >
                          <u>Sell Inventory</u>
                        </button> */}
                        {/* {shouldShowButton(showSellNav) && ( */}
                        <button
                          type="button"
                          className="border-0"
                          onClick={handleClickSell}
                        >
                          <u>Add Cost</u>
                        </button>
                        <DealerAddInventoryNav
                          show={showModalAddInventory}
                          handleClose={handleAddInventoryClose}
                        />
                        <SellDataNav
                          show={showModalSellData}
                          handleClose={handleModalCloseSell}
                          status={false}
                        />
                        <SellData
                          show={showModalSell}
                          handleClose={handleModalCloseSell}
                          status={false}
                          IsThisAddCost={IsThisAddCost}
                          handleModalOpenAdd={handleModalOpenAdd}
                        />

                        <AddlistingData
                          show={showAddList}
                          showModalImage={showModalImage}
                          handleClose={handleModalCloseAdd}
                          showModalAdd={true}
                          setShowModalImage={setShowModalImage}
                          handleCloseImgModal={handleCloseImgModal}
                          handleModalCloseSell={handleModalCloseSell}
                          addlistingData={selectedVehicle}
                          addlistionIdShow={3}
                          addCostStatus={1}
                          // handleInventoryDelete={handleDelete}
                          // InventoryIndex={InventoryIndex}
                          addCost={"Mantainance"}
                        />
                        <LoanApplication
                          show={showModalLoanApplication}
                          handleClose={handleLoanApplicationClose}
                        />
                        {/* )} */}

                        {/* <Link
                          to="/"
                          style={{
                            backgroundColor: "transparent",
                            padding: "0px",
                            width: "auto",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            // src="/websiteIcon.png"
                            src={Image.websiteIcon}
                            alt="Home"
                            height="38px"
                            width="38px"
                          />
                        </Link> */}
                      </div>
                    </div>
                  )}
                  {/* User icon and drop down */}
                  <div className="user_icon_name_tablet">
                    <span
                      style={{
                        color: "red",
                        fontSize: "43px",
                        cursor: "pointer",
                        marginTop: "-17px",
                        marginRight: "17px",
                      }}
                      onClick={() => setShowNoty(!showNoty)}
                    >
                      <img
                        src={Image.bellIcons}
                        style={{ width: "51px", height: "46px" }}
                      />
                      {showNoty && (
                        <div
                          className="noti_dropdown table-scrollbar"
                          style={{
                            position: "absolute",
                            display: "flex",
                            // gap: "3.5rem",
                            top: "70px",
                            right: "14px",
                            zIndex: "999",
                            padding: "1rem",
                          }}
                        >
                          {" "}
                          {/* <img
                              src={Image.sidebarMyFavrites}
                              alt="Favourite Icon"
                              height={30}
                              width={30}
                              title="My Favourite"
                            />
                            <img
                              src={Image.messanger}
                              alt="Messanger Icon"
                              height={30}
                              width={30}
                              title="Messages"
                            />
                            <img
                              src={Image.offers}
                              alt="Offers Icon"
                              height={30}
                              width={30}
                              title="Offers"
                            /> */}
                          <div>
                            <h4 className="noti_head_first">Notification</h4>
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">1</span> Message
                              Lead
                            </h6>
                            <p className="noti_con">
                              <FaCar />
                            </p>
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">2</span> Incoming
                              call Lead
                            </h6>
                            <p className="noti_con">
                              <IoCall />
                            </p>
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">3</span> New Offer
                              Coming from Omar
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">4</span> Inventory
                              Tax Due
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">5</span> Comptroller
                              Tax Due
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">6</span> Inventory
                              Tax Due from 246
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">7</span> Annual
                              Deceleration from 244
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">8</span> Franchise
                              Tax Report Due
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">9</span> Owner
                              Report Information Due
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">10</span> Federal
                              Tax Due Notification
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">11</span> State Tax
                              Due Notification
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">12</span> Dealer
                              License Renewal.
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">13</span> Suerity
                              Bond Renewal.
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                          <div className="notification-nav">
                            <h6 className="noti_head">
                              <span className="number-noti">14</span> OCCC
                              License Renewal.
                            </h6>
                            {/* <p className="noti_con">Is this still available.</p> */}
                          </div>
                        </div>
                      )}
                    </span>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        // src={
                        //   userStoreData.profile_picture
                        //     ? Image.power_button
                        //     : // ? `${imgUrl}/${userStoreData.id}/${userStoreData.profile_picture}`
                        //       Image.power_button
                        // }
                        src={Image.logouta}
                        onMouseEnter={() => setIsHoveredLogo(true)}
                        onMouseLeave={() => setIsHoveredLogo(false)}
                        alt="Avatar Image for Tablet"
                        height={30}
                        width={30}
                        // onClick={handleShowUserActionAfterLogion}
                        onClick={handleLogoutTablet}
                        className="avatar_image_icon"
                      />
                      {isHoveredLogo && (
                        <div
                          style={{
                            position: "absolute",
                            // bottom: "30px",
                            // left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            color: "white",
                            padding: "5px",
                            borderRadius: "4px",
                            whiteSpace: "nowrap",
                            zIndex: 1,
                          }}
                        >
                          Logout
                        </div>
                      )}
                    </div>
                    {/* <p onClick={handleShowUserActionAfterLogion}>
                      {userStoreData.firstName
                        ? `${userStoreData.firstName}`
                        : "User"}
                    </p> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* ------------------------------------------------------Second Navbar -----------------------------------------------------------------------------------*/}

      <div className="parent_nav_b">
        {/* Logo Image Div */}

        {/* Saerching input div */}
        <div className="parent__search">
          {/* DropDown of category for Search */}
        </div>
        {/* User Related for Tablet */}

        {/* <div className="user_div_for_tablet">
          {userStoreData.email === "" ? (
            <div>
              <form className=" dealer_section_for_big_laptop">
                <Link
                  className="btns btn-outline-dark"
                  to="/dealer_facilities_home"
                  type="button"
                  style={{ color: "#ffffff" }}
                >
                  <i className="fa-regular fa-user"></i> Dealers
                </Link>
                <button
                  className="btns btn-outline-dark btn-margin"
                  type="button"
                  style={{ color: "#ffffff" }}
                >
                  <i className="fa-solid fa-car-rear"></i> Sell My Car
                </button>
              </form>
              <div className="dealer_section_for_tablet">
                <span>
                  <FaUser />
                </span>
                <span>
                  <FaCarAlt />
                </span>
              </div>
            </div>
          ) : (
            <div className="user_icon_name_tablet">
              <img
                src={
                  userStoreData.profile_picture
                    ? `https://dacbuckets3.s3.amazonaws.com/${userStoreData.profile_picture}`
                    : Image.avatar_img
                }
                alt="Avatar Image for Tablet"
                height={55}
                width={55}
                onClick={handleShowUserActionAfterLogion}
              />
              <p onClick={handleShowUserActionAfterLogion}>
                {userStoreData.firstName
                  ? `${userStoreData.firstName}`
                  : "User"}
              </p>
            </div>
          )}
        </div> */}
      </div>
      {showUserActionsAfterLogin ? (
        <div className="user_actions_after_login_tablet">
          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/dashboard">Dashboard</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/dashboard">Dashboard</Link>
            ) : null} */}

          {/* <Link to="/myprofile/customerdetails">Profile</Link> */}
          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/customerdetails">Profile</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/dealer-master/bussiness-information">
                Profile
              </Link>
            ) : null} */}

          {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}
          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/addlist">Add Inventory</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/inventory/add">Add Inventory</Link>
            ) : null} */}

          {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}
          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/active">Active Inventory</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/inventory/active">Active Inventory</Link>
            ) : null} */}

          {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}
          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/sell">Sell Inventory</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/sell">Sell Inventory</Link>
            ) : null} */}

          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
            <p> */}
          {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}

          {/* <Link to="/dealer/inventory/sold">Sold Inventory</Link> */}
          {/* </p>
          ) : null} */}

          {/* <p>
            {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}
          {/* {userStoreData && userStoreData.roles === "User" ? (
              <Link to="/myprofile/bought">Bought & Sold</Link>
            ) : null}
            {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/bought_sold">Bought & Sold</Link>
            ) : null} */}
          {/* </p> */}
          {/* <p>
            {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}
          {/* {userStoreData && userStoreData.roles === "User" ? (
              <Link to="/myprofile/">Add. Manager</Link>
            ) : null}
            {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/dealer_add_manager">Add. Manager</Link>
            ) : null} */}
          {/* </p> */}

          {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}

          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/">Messages</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/dealer_add_manager">Messages</Link>
            ) : null} */}

          {/* <Link to="/myprofile/addlist">Add Inventory</Link> */}
          {userStoreData && userStoreData.roles === "User" ? (
            <p>
              <Link to="/myprofile/">Offers</Link>
            </p>
          ) : null}
          {/* {userStoreData && userStoreData.roles === "Dealer" ? (
              <Link to="/dealer/dealer_add_manager">Offers</Link>
            ) : null} */}

          {/* <p onClick={() => dispatch(logoutUser())} >Logout</p> */}
          <p onClick={handleLogoutTablet} style={{ cursor: "pointer" }}>
            Logout
          </p>
        </div>
      ) : // <UserRoutes setShowUserActionsAfterLogin={setShowUserActionsAfterLogin} apiData={apiData}/>
      null}
    </>
  );
};

export default Navbar;
