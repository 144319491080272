import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../../imgData";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import './VehicleCard.css'
const imgUrl = process.env.REACT_APP_IMG_URL;

const VehicleCard = ({ index, selectedCard, handleSelected, data }) => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = data.multimedia;
  const noImage = Image.noImage;

  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${dealerId}/${image}`;
        }
      }
    }
    return null;
  };

  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;

  // console.log('index', index);
  useEffect(() => {
    dispatch(
      setRetailData({
        imageUrl: imageUrl,
      })
    );
  }, [imageUrl]);

  console.log("selectedCard******************************************", data);
  const selectedVehicle = useSelector((state) => state.sell.selectedVehicle);
  const [isIdMatch, setisIdMatched] = useState(false);
  useEffect(() => {
    if (selectedVehicle) {
      if (selectedVehicle.id === data.id) {
        console.log("Yes both are equal");
        setisIdMatched(true);
      } else {
        setisIdMatched(false);
      }
    }
  }, [selectedVehicle]);

  // useEffect(()=>{
  //   dispatch(setRetailData({
  //     listingId:selectedVehicle.id,
  //   }));
  // },[selectedVehicle]);

  return (
    <>
      <Col
        sm={12}
        className="px-0 mb-4"
        onClick={() => {
          handleSelected(index);
        }}
      >
        <Link to={`#${data.stock_no}#${data.id}`}>
          <Container>
            <Row
              className="v-card"
              style={{
                backgroundColor: isIdMatch ? "#cfcaca" : "",
                boxShadow: isIdMatch ? "0px 5px 5px #cccccc" : "",
              }}
            >
              <Col  className=" d-flex justify-content-center p-2 sectionContainer">
                <img src={imageUrl} alt="..." />
              </Col>
              <Col  className=" d-flex  align-items-center sectionContainer">
                <div className="divide"></div>
                <div className=" d-flex flex-column justify-content-evenly w-100">
                  <h5 className="m-auto">
                    {data.year} {data.make} {data.model}
                  </h5>
                </div>
              </Col>
              <Col  className=" d-flex  align-items-center px-0 sectionContainer">
                <div className="divide"></div>
                <div className=" d-flex flex-column justify-content-evenly w-100">
                  <p className="mb-0 m-auto">
                    <span className="orange-color ">VIN: </span>
                    {data.vin}
                  </p>
                  {data.mileage_in ? <p className=" mb-0"></p> : null}
                </div>
              </Col>
              <Col  className="d-flex align-items-center ps-4 sectionContainer">
                <div className="divide"></div>
                <div className="d-flex justify-content-evenly w-100">
                  <h3 className="m-auto orange-color">
                    ${new Intl.NumberFormat().format(data.listing_price)}
                    </h3>
                  </div>
              </Col>
            </Row>
          </Container>
        </Link>
      </Col>
    </>
  );
};

export default VehicleCard;
