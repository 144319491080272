import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import styles from "./SoldPreviewModal.module.css";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import PDF from "../../DealerSell/components/PDF.jsx";
import usaStates from "../DealerAddInventory/usaStates.json";
import { IoDocumentsSharp } from "react-icons/io5";
import Image from "../../../imgData/index.js";

const SoldPreviewModal = ({
  data,
  showPreviewModal,
  setShowPreviewModal,
  imageUrl,
}) => {
  const userData = useSelector((state) => state.user);
  const [showPdfs, setShowPdfs] = useState(false);
  const [showForms, setShowFormsPdfs] = useState(false);
  const [showFormsPreview, setShowFormsPreview] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  //for forms
  const [selectAllForms, setSelectAllForms] = useState(false);
  const [selectedState, setSelectedState] = useState("TX - Texas");
  const [search, setSearch] = useState("");
  console.log("Searching => ", search);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);
  const [isCallFunc, setisCallFunc] = useState(false);
  const [checkBoxesToShow, setCheckboxesToShow] = useState([
    "AgreementInsurance",
    "billOfSale",
    "buyerGuide",
    "odometerDisclosure",
    "titleApp",
    "powerOfAttorney",
    "rebuildTitleDisclosure",

    "statementOfFacts",
    "airbagDisclosure",
    "serviceContract",
    "countyOfTitleIssuance",
  ]);

  // State to manage checkboxes for all forms
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: false,
    buyerGuide: false,
    odometerDisclosure: false,
    titleApp: false,
    AgreementInsurance: false,
    creditBureauDisclosure: false,
    rebuildTitleDisclosure: false,
    powerOfAttorney: false,
    retailContractInstallment: false,
    salesTaxExemptionCertificate: false,
    statementOfFacts: false,
    airbagDisclosure: false,
    serviceContract: false,
    countyOfTitleIssuance: false,
  });
  //OUTER FORMS STARTS HERE
  //Label checkboxes are the actual checkboxes that will be shown in outer forms
  const [labelCheckBox, setLabelCheckBox] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes({
      billOfSale: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
      statementOfFacts: newSelectAll,
      airbagDisclosure: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
    });
  };
  //for forms
  const handleSelectForms = () => {
    const newSelectAll = !selectAllForms;
    setSelectAllForms(newSelectAll);
    setFormsCheckboxes({
      billOfSale: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
      statementOfFacts: newSelectAll,
      airbagDisclosure: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
    });
  };

  //search in All forms
  const [searchInput, setSearchInput] = useState("");
  const [searchFormOutput, setSearchFormOnput] = useState([]);
  //for label check box in outer forms
  const [Formcheckboxes, setFormsCheckboxes] = useState({
    billOfSale: true,
    buyerGuide: true,
    odometerDisclosure: true,
    titleApp: true,
    AgreementInsurance: true,
    creditBureauDisclosure: true,
    rebuildTitleDisclosure: false,
    powerOfAttorney: true,
    retailContractInstallment: true,
    salesTaxExemptionCertificate: true,
    statementOfFacts: true,
    airbagDisclosure: false,
    serviceContract: true,
    countyOfTitleIssuance: true,
  });

  const reduxForms = [
    `title_issuance_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `tax_exemption_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `limited_attorney_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `buyers_guide_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `rebuilt_disclosure_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `title_registration_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `billOfSale_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `odometer_disclosure_${data?.addListing2?.vin?.slice(-8)}${
      userData.id
    }.pdf`,
    `salesContract_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `statement_of_facts_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `airbag_disclosure_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `service_contract_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `insurance_agreement_${data?.addListing2?.vin?.slice(-8)}${
      userData.id
    }.pdf`,
    `credit_bureau_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
  ];
  const pdfArray = [
    {
      pdf: reduxForms && reduxForms[6],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.billOfSale &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "billOfSale"),
    },
    {
      pdf: reduxForms && reduxForms[5],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.titleApp &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "titleApp"),
    },

    {
      pdf: reduxForms && reduxForms[1],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.salesTaxExemptionCertificate &&
        labelCheckBox &&
        labelCheckBox.find(
          (check) => check.id === "salesTaxExemptionCertificate"
        ),
    },
    {
      pdf: reduxForms && reduxForms[2],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.powerOfAttorney &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "powerOfAttorney"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[3],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.buyerGuide &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "buyerGuide"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[4],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.rebuildTitleDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "rebuildTitleDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[12],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.AgreementInsurance &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "AgreementInsurance"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[7],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.odometerDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "odometerDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[8],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.retailContractInstallment &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "retailContractInstallment"),
    },
    {
      pdf: reduxForms && reduxForms[9],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.statementOfFacts &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "statementOfFacts"),
    },
    {
      pdf: reduxForms && reduxForms[10],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.airbagDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "airbagDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[11],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.serviceContract &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "serviceContract"),
    },
    {
      pdf: reduxForms && reduxForms[0],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.countyOfTitleIssuance &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "countyOfTitleIssuance"),
    },
    {
      pdf: reduxForms && reduxForms[13],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.creditBureauDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "creditBureauDisclosure"),
    },
  ];
  const AllCheckBoxesFromAllForms = [
    {
      id: "AgreementInsurance",
      label: "Agreement To Provide Insurance",
    },
    { id: "billOfSale", label: "Bill of Sale /Buyer Order" },
    { id: "buyerGuide", label: "Buyer Guide" },
    {
      id: "creditBureauDisclosure",
      label: "Credit Bureau Disclosure",
    },
    { id: "odometerDisclosure", label: "Odometer Disclosure" },
    {
      id: "rebuildTitleDisclosure",
      label: "Rebuilt Title Disclosure",
    },
    { id: "titleApp", label: `${selectedState} -Title App I130u` },
    {
      id: "powerOfAttorney",
      label: `${selectedState} - Power Of Attorney`,
    },
    {
      id: "retailContractInstallment",
      label: `${selectedState} - Retail Contract Installment`,
    },
    {
      id: "salesTaxExemptionCertificate",
      label: `${selectedState} - Sales Tax Exemption Certificate`,
    },
    {
      id: "statementOfFacts",
      label: `Statement of Facts`,
    },
    {
      id: "airbagDisclosure",
      label: `Airbag disclosure`,
    },
    {
      id: "serviceContract",
      label: `Service Contract`,
    },
    {
      id: "countyOfTitleIssuance",
      label: `County of Title Issuance`,
    },
  ];

  console.log("Label Checkbox => ", labelCheckBox);
  useEffect(() => {
    if (checkBoxesToShow.length > 0) {
      const newArray = checkBoxesToShow
        .map((item) => AllCheckBoxesFromAllForms.find((obj) => obj.id === item))
        .filter(Boolean); // Remove any undefined results
      console.log("New Array in useEffect2 => ", newArray);
      setLabelCheckBox(newArray);
    } else {
      setLabelCheckBox([]);
    }
  }, [checkBoxesToShow]);

  //function to update the checkbox in outer forms
  const handleFormsCheckboxChange = (e) => {
    console.log("Updating the checkbox in the OUTER forms");
    const { id, checked } = e.target;
    console.log("ID => " + id + " checked => ", checked);

    setFormsCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  useEffect(() => {
    if (searchFormOutput.length > 0) {
      const filteredItem = checkBoxesToShow.map((item) =>
        searchFormOutput.find((obj) => obj.id === item)
      );

      console.log("filteredItem in useEffect => ", filteredItem);

      const updatedCheckboxes = {};
      filteredItem.forEach((item) => {
        if (item) {
          updatedCheckboxes[item.id] = true;
        }
      });

      setCheckboxes((prevData) => ({ ...prevData, ...updatedCheckboxes }));
    }
  }, [searchFormOutput, checkBoxesToShow]);
  //Method in the all forms modal to add the checked checkbox in the outer forms
  const handleAddToForms = () => {
    const checkedItems = Object.entries(checkboxes).filter(
      ([key, value]) => value === true
    );
    const trueObject = Object.fromEntries(checkedItems);

    setCheckboxesToShow(Object.keys(trueObject));
    setShowFormsPdfs(false);
    setSearchInput("");
  };
  //for searching the modal in the modal
  const handleSearchForms = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    setSearchFormOnput(
      AllCheckBoxesFromAllForms.filter((item) =>
        item.label.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };
  const handleCheckboxChange = (e, manualId = null) => {
    const id = manualId || e.target.id;
    const checked = manualId ? !checkboxes[id] : e.target.checked;

    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };
  useEffect(() => {
    const boolValue = Object.values(checkboxes).every((item) => item);
    console.log("Are all the checkboxes  checked ", boolValue);
    if (boolValue) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkboxes]);
  const handlePreviewSelect = () => {
    setShowPdfs(true);
    // dispatch(saveUser({ isSideBarOpen: false }));
  };
  const handleShowFormsSelect = () => {
    setShowFormsPdfs(true);
  };
  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchFormOnput(AllCheckBoxesFromAllForms);
    }
  }, [searchInput]);
  return (
    <>
      <Modal
        show={showPreviewModal}
        size="xl"
        onHide={() => setShowPreviewModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="p-2">
          <Modal.Title id="example-custom-modal-styling-title">
            <div className={styles.titleWrapper}>
              <span className={styles.saleType}>{data.saleType}</span>
              <div className={styles.carDetails}>
                <span>{data.addListing2.year_Make_Model}</span>
                <span>${data.addListing2.listing_price}</span>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0" }}>
          <div className={styles.bodyWrapper}>
            <div className={styles.bodyContainer}>
              <div className={styles.buyerImageContainer}>
                <div className={styles.ImageContainer}>
                  <img src={imageUrl} alt="car" />
                </div>
                <div className={styles.buyerContainer}>
                  <div className={styles.buyerInfo}>
                    <h5 className={styles.buyerHeader}>Buyer info: </h5>
                    <div className={styles.buyerDetails}>
                      <span>
                        <span>Name :</span>{" "}
                        <span>
                          {data.firstName} {data?.middleName || "-"}{" "}
                        </span>
                        {data.lastName}
                      </span>
                      <span>Phone Number : {data.phoneNumber}</span>
                      <span>Email address : {data?.email || "NA"}</span>
                      <span>ID Type : {data.idType}</span>
                      <span>ID State : {data.idState}</span>
                      <span>ID Number : {data.idNumber}</span>
                      <span>
                        Address : {data.address} {data.city}
                      </span>
                      <span>County : {data.county}</span>
                    </div>
                  </div>
                  <div className={styles.buyerInfo}>
                    <h5 className={styles.buyerHeader}>Co-Buyer info: </h5>
                    <div className={styles.buyerDetails}>
                      <span>
                        Name : {data.sellCoBuyer?.firstName || "NA"}{" "}
                        {data?.sellCoBuyer?.middleName}{" "}
                        {data.sellCoBuyer?.lastName}
                      </span>
                      <span>
                        Phone Number : {data.sellCoBuyer?.phoneNumber || "NA"}
                      </span>

                      <span>
                        Email address : {data?.sellCoBuyer?.email || "NA"}
                      </span>
                      <span>ID Type : {data.sellCoBuyer?.idType || "NA"}</span>

                      <span>
                        {" "}
                        ID State : {data.sellCoBuyer?.idState || "NA"}
                      </span>

                      <span>
                        ID Number : {data.sellCoBuyer?.idNumber || "NA"}
                      </span>

                      <span>
                        Address : {data.sellCoBuyer?.address || "NA"}{" "}
                        {data.sellCoBuyer?.city}
                      </span>
                      <span>County : {data.sellCoBuyer?.county || "NA"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.priceFeesMessageContainer}>
                <div className={styles.priceFeesContainer}>
                  <div className={styles.priceFeesTable}>
                    <div className={styles.priceFeesRowOne}>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>1. Sale Price</p>
                        <p className={styles.priceFeesValue}>
                          {data.addListing2?.listing_price || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>2. Trade In</p>
                        <p className={styles.priceFeesValue}>
                          {(data.addListing &&
                            data?.addListing?.purchase_price) ||
                            0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>
                          3. Cash Difference
                        </p>
                        <p className={styles.priceFeesValue}>
                          {(data.sellPriceFees &&
                            data.sellPriceFees?.cashDifference) ||
                            0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>4. Sales Tax</p>
                        <p className={styles.priceFeesValue}>
                          {data.sellPriceFees?.saleTaxValue || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Inventory Tax</p>
                        <p className={styles.priceFeesValue}>
                          {data.sellPriceFees?.mInventoryValue || 0}
                        </p>
                      </div>
                      {data.sellPriceFees?.sellPriceFeeItems?.length > 0 &&
                        data.sellPriceFees?.sellPriceFeeItems?.map(
                          (item, index) => (
                            <div className={styles.priceFeesRow}>
                              <p className={styles.priceFeesKey}>
                                {index + 6}. {item.itemName}
                              </p>
                              <p className={styles.priceFeesValue}>
                                {item.itemValue}
                              </p>
                            </div>
                          )
                        )}
                    </div>
                    <div className={styles.priceFeesRowTwo}>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Total Taxes</p>
                        <p className={styles.priceFeesValue}>
                          {data.sellPriceFees?.totalFees || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Total Fees</p>
                        <p className={styles.priceFeesValue}>
                          {(data.sellPriceFees?.totalFeesAndothers).toFixed(
                            2
                          ) || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Total Dues</p>
                        <p className={styles.priceFeesValue}>
                          {(data.sellPriceFees?.totalDue).toFixed(2) || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Payment Method</p>
                        <p className={styles.priceFeesValue}>
                          {data.sellPriceFees?.priceFeesPaymentMethod || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Payment</p>
                        <p className={styles.priceFeesValue}>
                          {data.sellPriceFees?.paymentPriceFees || 0}
                        </p>
                      </div>
                      <div className={styles.priceFeesRow}>
                        <p className={styles.priceFeesKey}>5. Balance Due</p>
                        <p className={styles.priceFeesValue}>
                          {data.sellPriceFees?.balanceDue || 0}
                        </p>
                      </div>
                      <div className={styles.formsButtonContainerInner}>
                        <p>Press View To Preview Printed Forms</p>
                        <button onClick={() => setShowPdfs(true)}>View</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={styles.MessageWrapper}
                >
                  <div className={styles.MessageContainer}>
                    <div className={styles.MessageHeader}>
                      <p>Messages</p>
                      <p>
                        <span
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#d1823a",
                            padding: "0.2rem 0.6rem",
                          }}
                        >
                          1
                        </span>
                      </p>
                    </div>
                    <div className={styles.MessageBox}>
                      <p className={styles.leftMessage}>
                        <p className={` ${styles.chatBody}`}>
                          <span>Micheal</span>
                          <span>I am interesed to test drive this car</span>
                        </p>
                      </p>
                      <p className={styles.rightMessage}>
                        <p className={` ${styles.chatBody}`}>
                          <span>Jackson</span>
                          <span>
                            Sure you can, all you need to do is to book a test
                            drive and we will send you a car to test drive.
                            Please click on the link below to book a test drive
                          </span>
                        </p>
                      </p>
                    </div>
                    <div className={styles.MessageInput}>
                      <input type="text" placeholder="Enter Your Message" />
                      <p style={{ marginBottom: "0" }}>
                        <span
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#d1823a",
                            padding: "0.2rem 0.6rem",
                            color: "white",
                            transform: "rotate(45deg)",
                          }}
                        >
                          <FaArrowRight />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formsButtonContainerOuter}>
                <p>Press View To Preview Printed Forms</p>
                <button onClick={() => setShowPdfs(true)}>View</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showPdfs}
        onHide={() => setShowPdfs(false)}
        size="xl"
        dialogClassName="modal-70w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Documents Printed while finalising the deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className=" px-1 text-center header_container d-flex justify-content-between">
            <Col sm={2} className="p-1">
              <h3>Forms</h3>
            </Col>
          </Row>

          <Row>
            <Col
              sm={7}
              className="d-flex flex-column justify-content-end align-items-end"
            >
              <div className="mb-3 w-75" style={{ marginRight: "-1%" }}>
                {/* <span
          onClick={handleSelectAll}
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {selectAll ? "Unselect All" : "Select All"}
        </span> */}
                <label
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllForms}
                    // onChange={handleSelectAll}
                    onChange={handleSelectForms}
                  />
                  <span>{selectAllForms ? "Unselect All" : "Select All"}</span>
                </label>
              </div>

              <Form className="test w-75 d-flex flex-column align-items-end">
                {labelCheckBox.length > 0 ? (
                  labelCheckBox?.map(({ id, label }) => (
                    <Form.Group
                      key={id}
                      className="mb-2 flex align-items-center justify-content-center"
                    >
                      <Form.Check
                        type="checkbox"
                        id={id}
                        checked={Formcheckboxes && Formcheckboxes[id]}
                        onChange={handleFormsCheckboxChange}
                        label={label}
                        className="form-option border-bottom pb-2 "
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "1rem",
                          paddingLeft: "30px",
                        }}
                      />
                    </Form.Group>
                  ))
                ) : (
                  <p>Please select checkboxes to show here</p>
                )}
              </Form>
            </Col>

            <Col
              sm={3}
              className="d-flex flex-column justify-content-center align-items-between gap-4"
            >
              <div className="d-flex justify-content-around flex-column mt-5">
                <Button
                  variant="light"
                  className="btn-orange mb-2 border-0 button-width d-flex align-items-center justify-content-center gap-2"
                  onClick={() => setShowFormsPreview(true)}
                  disabled={
                    (reduxForms && reduxForms?.length === 0) ||
                    (!Formcheckboxes.titleApp &&
                      !Formcheckboxes.salesTaxExemptionCertificate &&
                      !Formcheckboxes.powerOfAttorney &&
                      !Formcheckboxes.buyerGuide &&
                      !Formcheckboxes.rebuildTitleDisclosure &&
                      !Formcheckboxes.AgreementInsurance &&
                      !Formcheckboxes.billOfSale &&
                      !Formcheckboxes.odometerDisclosure &&
                      !Formcheckboxes.statementOfFacts &&
                      !Formcheckboxes.airbagDisclosure &&
                      !Formcheckboxes.serviceContract &&
                      !Formcheckboxes.countyOfTitleIssuance &&
                      !Formcheckboxes.creditBureauDisclosure &&
                      !Formcheckboxes.retailContractInstallment)
                  }
                  style={{ fontSize: "x-large", fontWeight: "500" }}
                >
                  <span style={{ fontSize: "x-large" }}>
                    {/* <FaRegEye /> */}
                    <img
                      src={Image.docxPreview}
                      height="25px"
                      alt="Docx preview"
                    />
                  </span>
                  Preview
                </Button>
                <Button
                  variant="light"
                  className="btn-orange border-0 button-width d-flex align-items-center justify-content-center gap-2 mb-2"
                  onClick={handleShowFormsSelect}
                  style={{ fontSize: "x-large", fontWeight: "500" }}
                >
                  <span style={{ fontSize: "x-large" }}>
                    <IoDocumentsSharp />
                  </span>{" "}
                  All Forms
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showForms}
        onHide={() => setShowFormsPdfs(false)}
        size="xl"
        dialogClassName="boxShadowInset"
        style={{ boxShadow: "inset 0 0 30px #dd8a3f" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Col className="text-right p-1 d-flex gap-3 justify-content-around">
              <div className="state">
                <Dropdown
                  show={isOpen}
                  onSelect={handleSelectState}
                  onToggle={(open) => setIsOpen(open)}
                  className="m-0 "
                >
                  <Dropdown.Toggle
                    size="sm"
                    className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {selectedState}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="state-drp">
                    {/* Hidden input to manage search */}
                    <input
                      type="text"
                      value={search}
                      ref={searchInputRef}
                      autofocus
                      onChange={handleSearchChange}
                      onFocus={() => setIsOpen(true)}
                      style={{
                        position: "absolute",
                        top: "-9999px",
                        left: "-9999px",
                      }} // Hide the input field
                    />
                    {filteredStates.length > 0 ? (
                      filteredStates.map((state) => (
                        <Dropdown.Item
                          key={state.name}
                          eventKey={state.name}
                          onClick={() => handleSelect(state.name)}
                        >
                          {state.name}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No states found</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="">
                <input
                  type="text"
                  value={searchInput}
                  placeholder="Search the forms here"
                  onChange={handleSearchForms}
                />
              </div>
            </Col>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="h-100 mx-auto w-75 d-flex gap-5">
          {/* {reduxForms && reduxForms?.map((item, i) => ( */}

          <Form className="test w-75 d-flex flex-column align-items-center ">
            {/* For ALL FORMS IN THE MODAL */}
            {/* {searchFormOutput.length > 0 ? (
                searchFormOutput.map(({ id, label }) => (
                  // {AllCheckBoxesFromAllForms.map(({ id, label }) => (
                  <Form.Group
                    key={id}
                    className="mb-2 flex align-items-center justify-content-center"
                  >
                    <Form.Check
                      type="checkbox"
                      id={id}
                      checked={checkboxes[id]}
                      onChange={handleCheckboxChange}
                      label={label}
                      className="form-option border-bottom pb-2 "
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "1rem",
                        paddingLeft: "30px",
                      }}
                    />
                  </Form.Group>
                ))
              ) : (
                <p>No form found for "{searchInput}"</p>
              )} */}
            <label
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
                display: "flex",
                gap: "0.5rem",
              }}
            >
              <input
                type="checkbox"
                checked={selectAll}
                // onChange={handleSelectAll}
                onChange={handleSelectAll}
              />
              <span>{selectAll ? "DeSelect All" : "Select All"}</span>
            </label>
            {searchFormOutput.length > 0 ? (
              searchFormOutput.map(({ id, label }) => (
                <Form.Group
                  key={id}
                  className="mb-2 flex align-items-center justify-content-center"
                >
                  <Form.Check
                    type="checkbox"
                    id={id}
                    checked={checkboxes[id]}
                    onChange={handleCheckboxChange}
                    label={
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckboxChange(null, id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {label}
                      </span>
                    }
                    className="form-option border-bottom pb-2"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1rem",
                      paddingLeft: "30px",
                    }}
                  />
                </Form.Group>
              ))
            ) : (
              <p>No form found for "{searchInput}"</p>
            )}
          </Form>
          <div className="d-flex flex-column gap-3  w-25 justify-content-center">
            <button
              className="px-3 py-2 bg-orange border-0 fw-bold text-light rounded-2"
              onClick={handleAddToForms}
            >
              Add to forms
            </button>
            <button
              className="px-3 py-2 bg-orange border-0 fw-bold text-light rounded-2"
              onClick={handlePreviewSelect}
            >
              Preview
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showFormsPreview}
        onHide={() => setShowFormsPreview(false)}
        dialogClassName="modal-70w"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {pdfArray.some((item) => item.isShow) && (
            <Modal.Body className="h-100 p-3">
              <PDF
                pdfArray={pdfArray.filter((item) => item.isShow)}
                setisCallFunc={setisCallFunc}
                isCallFunc={isCallFunc}
              />
            </Modal.Body>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default SoldPreviewModal;
