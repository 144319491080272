import React, { useEffect, useRef, useState } from "react";
import Styles from "./Styles/BusinesslocationCard.module.css";
import Image from "../../imgData";
import { GoPencil } from "react-icons/go";
import { MdMyLocation } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { MdPhoneInTalk } from "react-icons/md";
import { LiaFaxSolid } from "react-icons/lia";
import { ImWhatsapp } from "react-icons/im";
import { Prev } from "react-bootstrap/esm/PageItem";
import { fetchGeoMapData } from "../../dashboard/geoAPI";
import { DealerGeoAPI } from "./DealerGeoAPI";
import axios from "axios";
import { useSelector } from "react-redux";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

import { getCountyFromLocation } from "../CountyDetectAPI";
const libraries = ["places"];

const imgUrl = process.env.REACT_APP_IMG_URL;

const BusinessLocationCard = ({
  fetchLocation,
  editLocation,
  setEditLocation,
  handleAdd,
  index,
}) => {
  //
  const [businnessImageFile, setBusinnessImageFile] = useState(null);
  const [previewBusinnessImage, setPreviewBusinnessImage] = useState(null);
  const [error,setError] = useState("");
  const [apiDealerSuggestion, setAPIDealerSuggestion] = useState([]);
  const [showUploadIcon, setShowUploadIcon] = useState(false);
  const handleBusinessFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBusinnessImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewBusinnessImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { id } = useSelector((state) => state.user);
  const [rawPhoneNumber, setRawPhoneNumber] = useState("");
  // State initialization
  const [formData, setFormData] = useState({
    id: 0,
    userId: id,
    businessName: "",
    pNumber: "",
    financeLicense: "",
    exemptions: "",
    addressOne: "",
    addressTwo: "",
    county: "",
    email: "",
    website: "",
    phoneNumber: "",
    latitude: "",
    longitude: "",
    zip: "",
    fax: "",
    whatsApp: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [addValiError, setAddvaliError] = useState(false);
  
  const autocompleteRef = useRef(null);
// for lat long=----------------------------------------------------
const getCoordinates = async (address) => {
  console.log("Call")
  // const baseUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const baseUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json`;
  const response = await fetch(`${baseUrl}?q=${encodeURIComponent(address)}&format=json`);
  const data = await response.json();

  if (data.length > 0) {
    return {
      latitude: data[0].lat,
      longitude: data[0].lon,
    };
  } else {
    throw new Error('Geocoding failed: No results found');
  }
};

useEffect(() => {
  if (formData.addressOne) {
    getCoordinates(formData.addressOne)
      .then(coords => {
        setFormData(prev => ({
          ...prev,
          latitude: coords.latitude,
          longitude: coords.longitude,
        }));
      })
      .catch(error => {
        console.error(error);
      });
  }
}, [formData.addressOne]);
  // Effect to handle form validation
  useEffect(() => {
    const requiredFields = [
      "businessName",
      "pNumber",
      "addressOne",
      "county",
      "email",
      "phoneNumber",
    ];

    const isValid = requiredFields.every(
      (field) => formData[field] && formData[field].trim() !== ""
    );

    setIsFormValid(isValid);
  }, [formData]);

  // Effect to handle address suggestions
  useEffect(() => {
    if (apiDealerSuggestion.length > 0) {
      const suggestion = apiDealerSuggestion[0];

      setFormData((prevData) => ({
        ...prevData,
        businessName: suggestion.dealerName || "",
        addressOne: suggestion.addressOne || "",
        county: suggestion.county || "",
        addressTwo: suggestion.addressTwo || "",
        phoneNumber: suggestion.dealerShipPhone || "",
        fax: suggestion.dealershipPhoneNumber || "",
        website: suggestion.dealershipPhoneNumber || "",
        email: suggestion.email || "",
        managerName: suggestion.managerName || "",
        managerTitle: suggestion.managerTitle || "",
      }));
    }
  }, [apiDealerSuggestion]);

  // Effect to handle editing location
  useEffect(() => {
    if (editLocation) {
      setFormData(editLocation);
      const phoneNumber = editLocation?.phoneNumber;
      if (phoneNumber) {
        setFormData((prevData) => ({
          ...prevData,
          phoneNumber: formatPhoneNumber(phoneNumber),
        }));
        setRawPhoneNumber(phoneNumber);
      }
      setBusinnessImageFile(editLocation.profilePicture);
      setPreviewBusinnessImage(
        editLocation.profilePicture &&
        `${imgUrl}/${id}/${editLocation.profilePicture}`
      );
    }
  }, [editLocation]);

  // Handle location input
  const handleLocationInput = async (e) => {
    const { name, value } = e.target;

    // Regex patterns
    const zipCodePattern = /^\d{0,5}$/;
    const phoneNumberPattern = /^[\d()-\s]{0,14}$/;

    let updatedValue = value;

    if (name === "zipCode" && !zipCodePattern.test(value)) {
      updatedValue = value.slice(0, -1);
    }

    if (name === "phoneNumber") {
      if (!phoneNumberPattern.test(value)) {
        updatedValue = value.slice(0, -1);
      } else {
        updatedValue = formatPhoneNumber(value);
        setRawPhoneNumber(value.replace(/[^\d]/g, ""));
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  // Handle address selection
  const handlePlaceSelect = () => {
    const addressObject = autocompleteRef.current.getPlace();
    if (addressObject.formatted_address) {
      setFormData((prevData) => ({
        ...prevData,
        addressOne: addressObject.formatted_address,
      }));
      fetchCounty(addressObject.formatted_address);
    }
  };

  // Handle location function
  const handleLocation = async () => {
    try {
      const res = await DealerGeoAPI();
      setFormData((prevData) => ({
        ...prevData,
        addressOne: res.formattedAddress,
        city: res.city,
        state: res.state,
        zip: res.zipCode,
      }));

      fetchCounty(res.formattedAddress);
    } catch (error) {
      console.log("API Side Error");
    }
  };

  // Fetch county information
  const fetchCounty = async (address) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const res = await getCountyFromLocation(apiKey, address);
      setFormData((prevData) => ({
        ...prevData,
        county: res,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch dealer data from the API
  const fetchDealerData = async (zip, pNumber) => {
    const fd = new FormData();
    if (zip) fd.append('zipCode', zip);
    if (pNumber) fd.append('pNumber', pNumber);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/inventory/get`,
        fd
      );
      setAPIDealerSuggestion(response.data);
    } catch (error) {
      console.log('Error fetching dealer data:', error);
      setAPIDealerSuggestion([]);
    }
  };

  // Handle input blur to fetch dealer data
  const handleBlur = () => {
    if (formData.zip || formData.pNumber) {
      fetchDealerData(formData.zip, formData.pNumber);
    }
    setError("");
  };

  // Format phone number
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  // Handle saving the form data
  const handleSave = async () => {
    try {
      const fd = new FormData();
      fd.append("profile_pic", businnessImageFile);
      const updatedState = {
        ...formData,
        phoneNumber: rawPhoneNumber,
      };
      fd.append("dealer_data", JSON.stringify(updatedState));
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/dealer/save`,
        fd
      );

      if (res.status === 200) {
        fetchLocation();
        setFormData({
          id: 0,
          userId: id,
          businessName: "",
          pNumber: "",
          financeLicense: "",
          exemptions: "",
          addressOne: "",
          addressTwo: "",
          county: "",
          email: "",
          website: "",
          phoneNumber: "",
          latitude: "",
          longitude: "",
          zip: "",
          fax: "",
          whatsApp: "",
        });
        setBusinnessImageFile(null);
        setPreviewBusinnessImage(null);
      }
    } catch (error) {
      console.log("Server side while saving the data");
    }
  };

  // Handle address validation
  useEffect(() => {
    const regex = /^([^,]+,){3,}[^,]+$/;
    setAddvaliError(!regex.test(formData.addressOne));
  }, [formData]);

  // Handle location zip retrieval
  const handleLocationZip = async () => {
    try {
      const res = await DealerGeoAPI();
      setFormData((prevData) => ({
        ...prevData,
        zip: `${res.zipCode}`,
      }));
    } catch (error) {
      console.log("API Side Error");
    }
  };
  console.log("formDataapiGoole",formData);
  return (
    <div className={Styles.bussiness_location_card_wrapper}>
      <div className={Styles.bussiness_location_card_container}>
        <div className={Styles.location_card_header_wrapper}>
          <div className={Styles.location_card_header}>
            <p>Business Information</p>
            <p>Location-{index + 1}</p>
          </div>
        </div>
        <div className={Styles.location_card_main_1}>
          <div className={Styles.location_card_main_1_inputs}>
           <p style={{color:"red"}}>{error}</p>
            <p style={{display:"flex",width:"100%",gap:"2rem"}}>
            <p className={Styles.dealer_address_line_1_parent} style={{width:"50%"}}>
                  <label
                    htmlFor="dealer_address_line_1"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                   Zip Code
                  </label>
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    // googleMapsApiKey="AIzaSyAidNgKafDZvB6960WgN84sAV9hHqRYGYY"
                    libraries={libraries}
                  >
                    {/* <Autocomplete
                      onLoad={(autocomplete) =>
                        (autocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={handlePlaceSelect}
                      style={{ zIndex: "10" }}
                    > */}
                      <input
                        type="text"
                        id="dealer_address_line_1"
                        className={Styles.input_text_field}
                        style={{ fontWeight: "bolder" }}
                        value={formData?.zip}
                        onChange={handleLocationInput}
                        onBlur={handleBlur}
                        name="zip"
                      />
                    {/* </Autocomplete> */}

                    <span onClick={handleLocationZip}>
                      <MdMyLocation />
                    </span>
                  </LoadScript>
                  <span onClick={handleLocationZip}>
                    <MdMyLocation />
                  </span>
                </p>
            <p style={{width:"50%"}}>
              <label htmlFor="dealer_p_num" className={Styles.input_label}>
                P. Number
              </label>
              <input
                type="text"
                id="dealer_p_num"
                name="pNumber"
                value={formData?.pNumber}
                onChange={handleLocationInput}
                className={Styles.input_text_field}
                onBlur={handleBlur}
              />
            </p></p>
            <p>
              <label
                htmlFor="dealer_business_name"
                className={Styles.input_label}
              >
                Dealer's Business Name
              </label>
              <input
                type="text"
                id="dealer_business_name"
                value={formData?.businessName}
                name="businessName"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
            <p>
              <label
                htmlFor="dealer_finance_license"
                className={Styles.input_label}
              >
                Finance License{" "}
              </label>
              <input
                type="text"
                id="dealer_finance_license"
                name="financeLicense"
                value={formData?.financeLicense}
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
            <p>
              <label
                htmlFor="dealer_sales_exemption"
                className={Styles.input_label}
              >
                Sales and Use Tax/Exemption
              </label>
              <input
                type="text"
                id="dealer_sales_exemption"
                name="exemptions"
                value={formData?.exemptions}
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
          </div>
          <div
            className={Styles.card_upload_image_container}
            onMouseEnter={() => setShowUploadIcon(true)}
            onMouseLeave={() => setShowUploadIcon(false)}
          >
            <img
              src={previewBusinnessImage || Image.upload_icon}
              alt="upload image"
              style={{ cursor: "pointer" }}
              onClick={() => document.getElementById("business_image").click()}
            />
            <input
              type="file"
              id="business_image"
              style={{ display: "none" }}
              onChange={handleBusinessFile}
              accept="image/*"
            />
            {showUploadIcon && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  document.getElementById("business_image").click()
                }
              >
                <GoPencil />
              </span>
            )}
          </div>
        </div>
        <div className={Styles.location_card_main_2}>
          <div className={Styles.business_address_1}>
            <p className={Styles.dealer_address_line_1_parent}>
              <label
                htmlFor="dealer_address_line_1"
                className={Styles.input_label}
              >
                Address Line 1
              </label>
              {/* <input
                type="text"
                id="dealer_address_line_1"
                value={formData.addressOne}
                name="addressOne"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              /> */}

              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                // googleMapsApiKey="AIzaSyAidNgKafDZvB6960WgN84sAV9hHqRYGYY"
                libraries={libraries}
              >
                <Autocomplete
                  onLoad={(autocomplete) =>
                    (autocompleteRef.current = autocomplete)
                  }
                  onPlaceChanged={handlePlaceSelect}
                >
                  <input
                    type="text"
                    id="dealer_address_line_1"
                    className={Styles.input_text_field}
                    // placeholder="Enter your address"
                    value={formData?.addressOne}
                    onChange={handleLocationInput}
                    name="addressOne"
                  />
                </Autocomplete>

                <span onClick={handleLocation}>
                  <MdMyLocation />
                </span>
              </LoadScript>
              {addValiError && formData?.addressOne?.length > 0 && (
                <p style={{ color: "red", fontSize: "medium", marginBottom : "0" }}>
                  {" "}
                  Please format your address with three commas: "Street, City,
                  State, ZIP"
                </p>
              )}
            </p>
            {/* <p className={Styles.cityLocation}>
              <label
                htmlFor="dealer_business_city"
                className={Styles.input_label}
              >
                City
              </label>
              <input
                type="text"
                id="dealer_business_city"
                value={formData.city}
                name="city"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p> */}
            <p className={Styles.addresstwo}>
              <label
                htmlFor="dealer_business_state"
                className={Styles.input_label}
              >
                Address Line 2
              </label>
              <input
                type="text"
                id="dealer_business_state"
                value={formData?.addressTwo}
                name="addressTwo"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
            <p className={Styles.stateLocation}>
              <label
                htmlFor="dealer_business_state"
                className={Styles.input_label}
              >
                County
              </label>
              <input
                type="text"
                id="dealer_business_state"
                value={formData?.county}
                name="county"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
            {/* <p className={Styles.zipCodeLocation}>
              <label
                htmlFor="dealer_business_zip_code"
                className={`${Styles.input_label} `}
              >
                Zip Code
              </label>
              <input
                type="text"
                id="dealer_business_zip_code"
                value={formData.zipCode}
                name="zipCode"
                pattern="\d*"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p> */}
          </div>
        </div>
        <div className={Styles.dealer_contact_information_parent}>
          <p className={Styles.dealer_contact_information_header}>
            Contact Information
          </p>
          <div className={Styles.business_address_1}>
            <p>
              <label
                htmlFor="dealer_email_address"
                className={Styles.input_label}
              >
                <span
                  style={{
                    color: "orange",
                    fontSize: "large",
                    marginRight: "0.3rem",
                  }}
                >
                  <MdOutlineMail />
                </span>
                <span>Email Address</span>
              </label>
              <input
                type="text"
                id="dealer_email_address"
                value={formData?.email}
                name="email"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
            <p>
              <label htmlFor="dealer_website" className={Styles.input_label}>
                <span
                  style={{
                    color: "orange",
                    fontSize: "large",
                    marginRight: "0.3rem",
                  }}
                >
                  <TbWorldWww />
                </span>
                <span>Website</span>
              </label>
              <input
                type="text"
                id="dealer_website"
                name="website"
                value={formData?.website}
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
          </div>
          <div className={Styles.business_address_2}>
            <p>
              <label
                htmlFor="dealer_business_phone_number"
                className={Styles.input_label}
              >
                <span
                  style={{
                    color: "orange",
                    fontSize: "large",
                    marginRight: "0.3rem",
                  }}
                >
                  <MdPhoneInTalk />
                </span>
                <span>Phone Number</span>
              </label>
              <input
                type="text"
                id="dealer_business_phone_number"
                value={formData?.phoneNumber}
                onChange={handleLocationInput}
                name="phoneNumber"
                // pattern="\d*"
                className={Styles.input_text_field}
              />
            </p>
            <p>
              <label
                htmlFor="dealer_business_fax"
                className={Styles.input_label}
              >
                <span
                  style={{
                    color: "orange",
                    fontSize: "large",
                    marginRight: "0.3rem",
                  }}
                >
                  <LiaFaxSolid />
                </span>
                <span>Fax</span>
              </label>
              <input
                type="text"
                id="dealer_business_fax"
                value={formData?.fax}
                name="fax"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
            <p>
              <label
                htmlFor="dealer_business_zip_code"
                className={Styles.input_label}
              >
                <span
                  style={{
                    color: "orange",
                    fontSize: "large",
                    marginRight: "0.3rem",
                  }}
                >
                  <ImWhatsapp />
                </span>
                <span>Whatsapp</span>
              </label>
              <input
                type="text"
                id="dealer_business_zip_code"
                value={formData?.whatsApp}
                name="whatsApp"
                onChange={handleLocationInput}
                className={Styles.input_text_field}
              />
            </p>
          </div>
          <div className={`${Styles.dealer_location_saver} `}>
            {editLocation ? (
              <button onClick={handleSave}>Update</button>
            ) : (
              <button
                onClick={handleSave}
                disabled={!isFormValid}
                className={`${Styles.saveBtn} ${
                  isFormValid ? Styles.dealer_location_saver_orange_bg : ""
                }`}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLocationCard;
