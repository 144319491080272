import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './DealerMarquee.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Image from '../../imgData';
import { useSelector } from 'react-redux';
const apiUrl = process.env.REACT_APP_API_URL;

const filteredImage = {
  honda: Image.honda,
  landrover: Image.landrover,
  mazda: Image.mazda,
  mercedesbenz: Image.mercedesbenz,
  mini: Image.mini,
  toyota: Image.toyota,
  alfaromeo: Image.alfaromeo,
  bentley: Image.bentley,
  mitsubishi: Image.mitsubishi,
  maserati: Image.maserati,
  volvo: Image.volvo,
  porsche: Image.porsche,
  volkswagen: Image.volkswagen,
  lamborghini: Image.lamborghini,
  audi: Image.audi,
  bmw: Image.bmw,
  buick: Image.buick,
  chevrolet: Image.chevrolet,
  chrysler: Image.chrysler,
  dodge: Image.dodge,
  ferrari: Image.ferrari,
  fiat: Image.fiat,
  ford: Image.ford,
  gmc: Image.gmc,
  ram: Image.ram,
  acura: Image.acura,
  astonmartin: Image.astonmartin,
  cadillac: Image.cadillac,
  genesis: Image.genesis,
  hyundai: Image.hyundai,
  infiniti: Image.infiniti,
  jaguar: Image.jaguar,
  jeep: Image.jeep,
  kia: Image.kia,
  lexus: Image.lexus,
  mclaren: Image.mclaren,
  nissan: Image.nissan,
  peugeot: Image.peugeot,
  renault: Image.renault,
  rollsroyce: Image.rollsroyce,
  subaru: Image.subaru,
  tesla: Image.tesla,
};

const DealerMarquee = () => {
  const { id } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleNavigate = () => {
    // navigate("/product");
  };

  const [isHovered, setIsHovered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carCounts, setCarCounts] = useState({});
  // const [items, setItems] = useState(Object.entries(filteredImage).filter(([key]) => carCounts[key] > 0));
  const [rows, setRows] = useState([]);
  const delay = 3000; // slider interval time in milliseconds

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % Object.keys(filteredImage).length);
      }, delay);
      return () => clearInterval(interval);
    }
  }, [isHovered]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('user_id', id);
        const response = await fetch(`${apiUrl}/listing/get_makes_count`, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        console.log("responsefilter data",data);
        const counts = data.reduce((acc, item) => {
          const makeKey = item.make.toLowerCase().replace(/-/g, '');
          if (filteredImage.hasOwnProperty(makeKey)) {
            acc[makeKey] = item.count;
          }
          return acc;
        }, {});
        setCarCounts(counts);
        console.log("Car counts:", counts); // Add this line
      } catch (error) {
        console.log("Fail", error); // Modified to log the error
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    const filteredItems = Object.entries(filteredImage).filter(([key]) => carCounts[key] > 0);
    const newRows = splitIntoRows(filteredItems, 3);
    setRows(newRows);
  }, [carCounts]);



  

  // useEffect(() => {
  //   if (filters.make.length > 0) {
  //     navigate("/dealer/inventory/active", { state: filters });
  //   }
  // }, [filters, navigate]);

  const handleClick = useCallback(async (make) => {
    let adjustedMake;
  
    switch (make) {
      case "MERCEDESBENZ":
        adjustedMake = "MERCEDES-BENZ";
        break;
      case "LANDROVER":
        adjustedMake = "LAND ROVER";
        break;
      default:
        adjustedMake = make;
    }

    try {
      const response = await fetch(`${apiUrl}/listing/filter_listing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ make: [adjustedMake] ,seller:[id]})
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      console.log("Filter listing response456:", data);
      navigate("/dealer/inventory/active", { state: data });
      // Handle the data as needed
      // For example, you could update the state or navigate to a different page with the data

    } catch (error) {
      console.log("Error fetching filter listing data:", error);
    }

  }, []);
  // const splitIntoRows = (items, rowCount) => {
  //   const result = Array.from({ length: rowCount }, () => []);
  //   items.forEach((item, index) => {
  //     result[index % rowCount].push(item);
  //   });
  //   return result;
  // };

  // const rows = splitIntoRows(Object.entries(filteredImage).filter(([key]) => carCounts[key] > 0), 3);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const sourceRowIndex = parseInt(result.source.droppableId.split('-')[1]);
    const destRowIndex = parseInt(result.destination.droppableId.split('-')[1]);
  
    const newRows = [...rows];
    const [removed] = newRows[sourceRowIndex].splice(result.source.index, 1);
    newRows[destRowIndex].splice(result.destination.index, 0, removed);
  
    setRows(newRows);
  };

  const splitIntoRows = (items, rowCount) => {
    const result = Array.from({ length: rowCount }, () => []);
    items.forEach((item, index) => {
      result[index % rowCount].push(item);
    });
    return result;
  };
  return (
    <section style={{ cursor: "pointer" }}>
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="balloon-slider">
        <div className="slider-wrapper">
          {rows.map((row, rowIndex) => (
            <Droppable droppableId={`row-${rowIndex}`} direction="horizontal" key={`row-${rowIndex}`}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="slider-row"
                >
                  {row.map(([key, imgSrc], index) => (
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="balloon-item"
                          style={{
                            ...provided.draggableProps.style,
                            width: `${100 / Math.max(12, row.length)}%`
                          }}
                        >
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="balloon-image">
                              <img
                                src={imgSrc}
                                className="ball-image"
                                alt={key}
                                onClick={() => handleClick(key.toUpperCase())}
                              />
                              <span><p>{carCounts[key] || 0}</p></span>
                            </div>
                          </Link>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </div>
    </DragDropContext>
  </section>
  );
};

export default DealerMarquee;
