import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import resetIcon from "./components/resetButton.png";
import toast from "react-hot-toast";
import "./BuyerInfo.css";
import {
  resetBuyerBusiness,
  resetBuyerName,
  resetBuyerSellData,
  resetCoBuyerBusiness,
  resetCoBuyerName,
  resetCoBuyerSellData,
  resetSellData,
  setActiveTab,
  setCoBuyerSellData,
  setSellData,
} from "../../../../Redux/slice/sellSlice";
import usaStates from "../../DealerInventory/DealerAddInventory/usaStates.json";
import styles from "./buyerInfo.module.css";
import { MdOutlineMyLocation } from "react-icons/md";
import { fetchGeoMapData } from "./geoAPI";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { getCountyFromLocation } from "../../CountyDetectAPI";
import CountrySelector from "./CountrySelector";
import { countryNames } from "./components/CountryJSON";
import ReactFocusLock from "react-focus-lock";
import { IoMdArrowRoundForward } from "react-icons/io";

const libraries = ["places"];
const BuyerInfo = ({ selectedVehicle, status }) => {
  const sellData = useSelector((state) => state.sell.buyer) || {};
  const sellCoBuyerData = useSelector((state) => state.sell.sellCoBuyer) || {};
  const sellCarDataPopData = useSelector(
    (state) => state.sell.selectedVehiclePopOut
  );
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [redRequiredFields, setRedRequiredFields] = useState([]);
  //formatyted phone number for usa
  const formattedCoBuyerPhoneNumber = sellCoBuyerData?.phoneNumber
    ? sellCoBuyerData?.phoneNumber?.replace(
        /^(\d{3})(\d{3})(\d{4})$/,
        "($1) $2-$3"
      )
    : "";
  const formattedBuyerPhoneNumber = sellData?.phoneNumber
    ? sellData?.phoneNumber?.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3")
    : "";

  useEffect(() => {
    if (formattedBuyerPhoneNumber) {
      const emptyFields = Object.keys(sellData)
        .filter((field) => field !== "phoneNumbers")
        .filter((field) => sellData[field] === "");
      setRedRequiredFields(emptyFields);
    }
  }, [formattedBuyerPhoneNumber]);
  const buyerAutocompleteRef = useRef(null);
  const coBuyerAutocompleteRef = useRef(null);

  // const handlePlaceSelect = (userType) => {
  //   console.log("userType =>", userType);
  //   const ref =
  //     userType === "buyer" ? buyerAutocompleteRef : coBuyerAutocompleteRef;
  //   const addressObject = ref?.current?.getPlace();
  //   const address = addressObject?.formatted_address || "";
  //   if (addressObject?.formatted_address) {
  //     if (userType === "buyer") {
  //       dispatch(setSellData({ buyer: { ...sellData, address } }));

  //       // Update the input field value
  //       handleInputChange("address", address);
  //       console.log("Handling Buyer change");

  //       fetchCounty(address, false);
  //     } else {
  //       dispatch(setCoBuyerSellData({ sellCoBuyer: { ...sellData, address } }));

  //       fetchCounty(address, true);
  //       console.log("Handling coBuyer change");
  //       handleCoBuyerInputChange("address", address);
  //     }
  //   }
  // };

  const handlePlaceSelect = (userType) => {
    console.log("userType =>", userType);
    const ref =
      userType === "buyer" ? buyerAutocompleteRef : coBuyerAutocompleteRef;
    const addressObject = ref?.current?.getPlace();

    const addressComponents = addressObject?.address_components || [];
    const formattedAddress = addressObject?.formatted_address || "";
    if (addressComponents) {
      const addressData = {
        apartments: "",
        street: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      };

      addressComponents.forEach((component) => {
        const types = component.types;

        if (types.includes("street_number")) {
          addressData.apartments = component.short_name;
        } else if (types.includes("route")) {
          addressData.street = component.short_name;
        } else if (
          types.includes("locality") ||
          types.includes("sublocality")
        ) {
          addressData.city = component.short_name;
        } else if (types.includes("administrative_area_level_1")) {
          addressData.state = component.short_name;
        } else if (types.includes("postal_code")) {
          addressData.zipcode = component.short_name;
        } else if (types.includes("country")) {
          addressData.country = component.short_name;
        }
      });
      console.log(" addressData => ", addressData);
      //for the address

      const addressParts = [];
      if (addressData.apartments) {
        addressParts.push(addressData.apartments);
      }

      //Add zipcode if it exists and is not empty
      if (addressData.street) {
        addressParts.push(addressData.street);
      }

      // Join the parts with ", " and trim any extra spaces
      const address = addressParts.join(" ").trim();

      if (userType === "buyer") {
        dispatch(
          setSellData({
            buyer: {
              ...sellData,
              address: address,
              city: addressData.city,
              state: addressData.state,
              zipCode: addressData.zipcode,
            },
          })
        );
        fetchCounty(formattedAddress, false);
      } else {
        dispatch(
          setCoBuyerSellData({
            sellCoBuyer: {
              ...sellCoBuyerData,
              address: address,
              city: addressData.city,
              state: addressData.state,
              zipCode: addressData.zipcode,
            },
          })
        );
        fetchCounty(formattedAddress, true);
      }
    }
  };
  const dispatch = useDispatch();

  const [showComBuyerTab, setShowComBuyerTab] = useState(false);
  const [modalState, setModalState] = useState({
    visible: false,
    type: "",
    forCoBuyer: false,
  });
  // console.log(modalState);
  const [idType, setIdType] = useState("");
  const [CoBuyeridType, setCoBuyerIdType] = useState("");
  const [idState, setIdState] = useState("");
  console.log("Selected id state => ", idState);
  const [idCountry, setIdCountry] = useState("");
  console.log("Selected id country => ", idCountry);
  const [coBuyeridState, coBuyersetIdState] = useState("");

  const handleModalClose = () => setModalState({ visible: false, type: "" });
  const handleModalShow = (type, forCoBuyer = false) => {
    setModalState({ visible: true, type, forCoBuyer });
    console.log("is it for  coBuyer", forCoBuyer);
  };
  console.log("modalState => ", modalState);
  const [isPassPortSelected, setIsPassPortSelected] = useState({
    forBuyer: false,
    forCoBuyer: false,
  });

  console.log(isPassPortSelected);
  const handleSelectType = (type) => {
    setIdType(type);
    console.log("Selected Type =>", type);
    if (type === "Passport") {
      setIsPassPortSelected((prevState) => ({
        ...prevState,
        forBuyer: true, //Passport selected for buyer
      }));
    } else {
      setIsPassPortSelected((prevState) => ({
        ...prevState,
        forBuyer: false, //Passport selected for buyer
      }));
    }

    dispatch(setSellData({ buyer: { ...sellData, idType: type } }));
    handleModalClose();
  };

  const handleCoBuyerSelectType = (type) => {
    setCoBuyerIdType(type);
    if (type === "Passport") {
      setIsPassPortSelected((prevState) => ({
        ...prevState,
        forCoBuyer: true,
      }));
    } else {
      setIsPassPortSelected((prevState) => ({
        ...prevState,
        forCoBuyer: false,
      }));
    }
    dispatch(
      setCoBuyerSellData({ sellCoBuyer: { ...sellCoBuyerData, idType: type } })
    );
    handleModalClose();
  };
  const handleSelectState = (state) => {
    setIdState(state);
    dispatch(setSellData({ buyer: { ...sellData, idState: state } }));
    handleModalClose();
  };
  const handleSelectCoBuyerState = (state) => {
    coBuyersetIdState(state);
    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: { ...sellCoBuyerData, idState: state },
      })
    );
    handleModalClose();
  };

  useEffect(() => {
    sessionStorage.setItem("sellData", JSON.stringify(sellData));
  }, [sellData]);

  const toggleComBuyerTab = () => {
    setShowComBuyerTab(!showComBuyerTab);
  };
  useEffect(() => {
    if (!showComBuyerTab) {
      dispatch(resetCoBuyerSellData());
    }
  }, [showComBuyerTab]);

  const handleInputChange = (field, value) => {
    dispatch(setSellData({ buyer: { ...sellData, [field]: value } }));
    console.log("Currently field typed is => ", field);
    if (value && value.trim() !== "" && redRequiredFields.includes(field)) {
      const array = [...redRequiredFields];
      const updatedArray = array.filter((item) => item !== field);
      setRedRequiredFields(updatedArray);
    }
  };

  const handleCoBuyerInputChange = (field, value) => {
    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: { ...sellCoBuyerData, [field]: value },
      })
    );
  };

  const handleGMapAPI = async (forCoBuyer = false) => {
    console.log(forCoBuyer);
    try {
      const res = await fetchGeoMapData();
      fetchCounty(res.formattedAddress, forCoBuyer);
      console.log("Response of GEO API", res);
      if (!forCoBuyer) {
        dispatch(
          setSellData({ buyer: { address: res.formattedAddress || "" } })
        );
      } else {
        dispatch(
          setCoBuyerSellData({
            sellCoBuyer: { address: res.formattedAddress || "" },
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCounty = async (address, forCoBuyer = false) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const res = await getCountyFromLocation(apiKey, address);
      console.log(res);
      if (forCoBuyer) {
        dispatch(
          setCoBuyerSellData({
            sellCoBuyer: { county: res || "" },
          })
        );
      } else {
        dispatch(setSellData({ buyer: { county: res || "" } }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // address same as above checkbox
  const [sameAddress, setSameAddress] = useState(false);

  useEffect(() => {
    if (sameAddress) {
      const buyerAddress = sellData && sellData.address;
      const buyerCounty = sellData && sellData.county;
      const buyerAddressTwo = sellData && sellData.address2;
      const buyerCity = sellData && sellData.city;
      const buyerState = sellData && sellData.state;
      const buyerZipCode = sellData && sellData.zipCode;
      dispatch(
        setCoBuyerSellData({
          sellCoBuyer: {
            address: buyerAddress || "",
            county: buyerCounty || "",
            address2: buyerAddressTwo || "",
            city: buyerCity || "",
            state: buyerState || "",
            zipCode: buyerZipCode || "",
          },
        })
      );
    } else {
      dispatch(
        setCoBuyerSellData({
          sellCoBuyer: {
            address: "",
            county: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
          },
        })
      );
    }
  }, [sameAddress]);
  // Check uncheck according to data in redux
  useEffect(() => {
    const buyerAddress = sellData && sellData?.address;
    const buyerCounty = sellData && sellData?.county;
    const buyerAddressTwo = sellData && sellData?.address2;
    const coBuyerAddress = sellData && sellCoBuyerData?.address;
    const coBuyerCounty = sellData && sellCoBuyerData?.county;
    const coBuyerAddressTwo = sellData && sellCoBuyerData?.address2;
    if (coBuyerAddress && coBuyerCounty && coBuyerAddressTwo) {
      if (
        (buyerAddress === coBuyerAddress && buyerCounty === coBuyerCounty) ||
        buyerAddressTwo === coBuyerAddressTwo
      ) {
        setSameAddress(true);
      } else {
        setSameAddress(false);
      }
    }
  }, [sellData, sellCoBuyerData]);

  const handleResetCoBuyerData = () => {
    dispatch(resetCoBuyerSellData());
  };
  const handleResetSellData = () => {
    dispatch(resetBuyerSellData());
  };

  //seraching on key down event
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastKey, setLastKey] = useState("");
  const [lastKeyTime, setLastKeyTime] = useState(0);
  const listRef = useRef(null);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.focus();
    }
  }, [listRef]);

  const handleKeyDown = (e) => {
    if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
      //removing the border
      listRef.current.style.border = "none";
      listRef.current.style.outline = "none";

      console.log(e.key);
      const currentTime = new Date().getTime();
      const key = e.key.toLowerCase();

      if (key !== lastKey || currentTime - lastKeyTime > 1000) {
        setCurrentIndex(-1);
      }

      setLastKey(key);
      setLastKeyTime(currentTime);

      let itemTofilter;

      const isPassportSelected = modalState.forCoBuyer
        ? isPassPortSelected.forCoBuyer
        : isPassPortSelected.forBuyer;
      const states = isPassportSelected ? countryNames : usaStates;

      itemTofilter = states.filter((state) => {
        const stateName = isPassportSelected ? state : state.name;
        return stateName.toLowerCase().startsWith(key);
      });

      if (itemTofilter && itemTofilter.length > 0) {
        const newIndex = (currentIndex + 1) % itemTofilter.length;
        console.log("newIndex => ", newIndex);
        setCurrentIndex(newIndex);
        const optionToSelect = itemTofilter[newIndex];
        console.log("optionToSelect => ", optionToSelect);

        const isPassportSelected = modalState.forCoBuyer
          ? isPassPortSelected.forCoBuyer
          : isPassPortSelected.forBuyer;

        if (isPassportSelected) {
          setIdCountry(optionToSelect);
        } else {
          setIdState(optionToSelect.name);
        }
        const optionElement = listRef.current.querySelector(
          `[data-value="${
            isPassportSelected ? optionToSelect : optionToSelect.name
          }"]`
        );

        if (optionElement) {
          const container = listRef.current; // Assuming listRef is the container
          const offsetTop =
            optionElement.getBoundingClientRect().top -
            container.getBoundingClientRect().top;
          const gap = 20; // Adjust this value to the desired gap above the item
          container.scrollTo({
            top: container.scrollTop + offsetTop - gap,
            behavior: "smooth",
          });
          optionElement.focus(); // Add this line to focus the element
          optionElement.tabIndex = 0;
          //Enabling the click on the selected item
        }
      }
    } else if (e.key === "Enter") {
      //Submitting the selected radio box in the input field and redux

      const isCoBuyer = modalState.forCoBuyer;
      const isPassportSelected = isCoBuyer
        ? isPassPortSelected.forCoBuyer
        : isPassPortSelected.forBuyer;
      const handleSelect = isCoBuyer
        ? handleSelectCoBuyerState
        : handleSelectState;

      if (!isPassportSelected) {
        handleSelect(idState);
      } else {
        handleSelect(idCountry);
      }
    }
  };
  // --------------------------only for got to next page-------------------
  //USA address validation
  const [addValiError, setAddvaliError] = useState(false);
  console.log("Is there any error in address validation => ", addValiError);
  useEffect(() => {
    const regex = /^([^,]+,){3,}[^,]+$/;

    console.log("Address Validation => ", regex.test(sellData.address));
    if (regex.test(sellData.address)) {
      setAddvaliError(false);
      dispatch(setSellData({ buyer: { ...sellData, isAddressValid: false } }));
    } else {
      setAddvaliError(true);
      dispatch(setSellData({ buyer: { ...sellData, isAddressValid: true } }));
    }
  }, [sellData.address]);

  const gotNextPage = () => {
    console.log("redRequiredFields in getNextPage => ", redRequiredFields);
    if (redRequiredFields.length === 0) {
      dispatch(setActiveTab("Prices and Fees"));
      console.log(
        "Now the length of the redRequiredFields is ",
        redRequiredFields
      );
      setIsSubmitButtonClicked(false);
    } else {
      setIsSubmitButtonClicked(true);
    }
  };
  useEffect(() => {
    console.log("SellData =>", Object.keys(sellData));
    const emptyFields = Object.keys(sellData)
      .filter(
        (field) =>
          field !== "address2" && field !== "middleName" && field !== "email"
      )
      .filter((field) => sellData[field] === "");
    setRedRequiredFields(emptyFields);
  }, [sellData]);

  //Here we will handle the buyer entity for both buyer and co-buyer
  const [isEntity, setIsEntity] = useState({
    buyer: sellData.isBuyerEntity || false,
    coBuyer: sellCoBuyerData.isCoBuyerEntity || false,
  });
  useEffect(() => {
    console.log("SellData =>", Object.keys(sellData));
    const emptyFields = Object.keys(sellData)
      .filter(
        (field) =>
          field !== (isEntity.buyer ? "firstName" : "businessName") &&
          field !== (isEntity.buyer ? "lastName" : "businessName") &&
          field !== "address2" &&
          field !== "middleName" &&
          field !== "email"
      )
      .filter((field) => sellData[field] === "");
    console.log("empty redRequiredFields => ", emptyFields);
    setRedRequiredFields(emptyFields);
  }, [sellData, isEntity]);

  const handleEntityCheckbox = (e, forWhom) => {
    setIsEntity((prevData) => ({
      ...prevData,
      [forWhom]: e.target.checked,
    }));
  };

  
  useEffect(() => {
    if (isEntity.buyer) {
      dispatch(resetBuyerName());
    } else {
      dispatch(resetBuyerBusiness());
    }
    if (isEntity.coBuyer) {
      dispatch(resetCoBuyerName()); 
    } else {
      dispatch(resetCoBuyerBusiness());
    }
  }, [isEntity]);

  return (
    <>
      <Container className="px-0">
        <Form>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            libraries={libraries}
          >
            <Row
              className="  mb-4 header_buyer2"
              style={{ width: "98%", marginLeft: "1.5%" }}
            >
              {!status ? (
                <Col>
                  {selectedVehicle ? selectedVehicle.year_Make_Model : null}
                  &emsp; &emsp;
                  {selectedVehicle ? `Vin:${selectedVehicle.vin}` : null}&emsp;
                  &emsp;
                  {selectedVehicle
                    ? `Mileage:${new Intl.NumberFormat().format(
                        selectedVehicle.mileage_in
                      )}`
                    : null}
                  &emsp; &emsp;
                  {selectedVehicle
                    ? `Listing Price:${new Intl.NumberFormat().format(
                        selectedVehicle.listing_price
                      )}`
                    : null}
                  &emsp; &emsp;
                </Col>
              ) : (
                <Col>
                  {sellCarDataPopData
                    ? sellCarDataPopData.addListing2.year_Make_Model
                    : null}
                  &emsp; &emsp;
                  {sellCarDataPopData
                    ? `Vin:${sellCarDataPopData.addListing2.vin}`
                    : null}
                  &emsp; &emsp;
                  {sellCarDataPopData
                    ? `Mileage:${new Intl.NumberFormat().format(
                        sellCarDataPopData.addListing2.mileage_in
                      )}`
                    : null}
                  &emsp; &emsp;
                  {sellCarDataPopData
                    ? `Listing Price:${new Intl.NumberFormat().format(
                        sellCarDataPopData.addListing2.listing_price
                      )}`
                    : null}
                  &emsp; &emsp;
                </Col>
              )}
            </Row>

            <Row
              className=" d-flex justify-content-start "
              style={{ width: "98%", marginLeft: "1%" }}
            >
              <Col sm={12} className="border pt-2 pb-2">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "1.5rem",
                    marginBottom: "0",
                  }}
                >
                  <Col
                    sm={4}
                    style={{
                      marginRight: "50px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <label
                      className="checkbox-container text-nowrap"
                      style={{
                        float: "right",
                        color: "#e8a262",
                        marginRight: "4px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isEntity.buyer}
                        onChange={(e) => handleEntityCheckbox(e, "buyer")}
                        style={{ marginRight: "10px" }}
                      />
                      Buyer Entity
                    </label>
                    <label
                      className="checkbox-container text-nowrap"
                      style={{
                        float: "right",
                        color: "#e8a262",
                        marginRight: "4px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={toggleComBuyerTab}
                        style={{ marginRight: "10px" }}
                      />
                      Co-Buyer
                    </label>
                  </Col>
                  <img
                    src={resetIcon}
                    alt="reset"
                    height={30}
                    onClick={handleResetSellData}
                    style={{ marginRight: "6px" }}
                  />
                  <span style={{ marginLeft: "15px" }}>
                    <button
                      type="button"
                      onClick={gotNextPage}
                      className="goto_next_page_btn btn-change5"
                    >
                      Save
                    </button>
                  </span>
                </div>
                <div className="d-flex" style={{ gap: "9rem" }}>
                  {isEntity.buyer ? (
                    <Form.Group
                      className={`${styles.formGroup}`}
                      style={{ fontWeight: "400", flex: "1" }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: "400",
                          color:
                            redRequiredFields.includes("businessName") &&
                            isSubmitButtonClicked
                              ? "red"
                              : "",
                        }}
                      >
                        *Business Name:
                      </Form.Label>
                      <div className={styles.inlineFields}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          style={{
                            fontWeight: "700",
                            boxShadow: "0px 3px 5px 1px lightgrey",

                            border:
                              redRequiredFields.includes("businessName") &&
                              isSubmitButtonClicked
                                ? "1px solid red"
                                : "",
                          }}
                          value={sellData.businessName}
                          onChange={(e) =>
                            handleInputChange("businessName", e.target.value)
                          }
                        />
                      </div>
                    </Form.Group>
                  ) : (
                    <>
                      <Form.Group
                        className={`${styles.formGroup}`}
                        style={{ fontWeight: "400", flex: "1" }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "400",
                            color:
                              redRequiredFields.includes("firstName") &&
                              isSubmitButtonClicked
                                ? "red"
                                : "",
                          }}
                        >
                          *First Name:
                        </Form.Label>
                        <div className={styles.inlineFields}>
                          <Form.Control
                            type="text"
                            placeholder=""
                            style={{
                              fontWeight: "700",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                              border:
                                redRequiredFields.includes("firstName") &&
                                isSubmitButtonClicked
                                  ? "1px solid red"
                                  : "",
                            }}
                            value={sellData.firstName}
                            onChange={(e) =>
                              handleInputChange("firstName", e.target.value)
                            }
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className={`${styles.formGroup}`}
                        style={{ fontWeight: "400", flex: "1" }}
                      >
                        <Form.Label style={{ fontWeight: "400", flex: "1" }}>
                          Middle Name:
                        </Form.Label>
                        <div className={styles.inlineFields}>
                          <Form.Control
                            type="text"
                            value={sellData.middleName}
                            style={{
                              fontWeight: "700",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                              border:
                                redRequiredFields.includes("middleName") &&
                                isSubmitButtonClicked
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) =>
                              handleInputChange("middleName", e.target.value)
                            }
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className={`${styles.formGroup}`}
                        style={{ fontWeight: "400", flex: "1" }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "400",

                            color:
                              redRequiredFields.includes("lastName") &&
                              isSubmitButtonClicked
                                ? "red"
                                : "",
                          }}
                        >
                          *Last Name:
                        </Form.Label>
                        <div className={styles.inlineFields}>
                          <Form.Control
                            type="text"
                            style={{
                              fontWeight: "700",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                              border:
                                redRequiredFields.includes("lastName") &&
                                isSubmitButtonClicked
                                  ? "1px solid red"
                                  : "",
                            }}
                            value={sellData.lastName}
                            onChange={(e) =>
                              handleInputChange("lastName", e.target.value)
                            }
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}
                </div>
                <div
                  className="d-flex "
                  style={{
                    paddingLeft: "0.4rem",
                    paddingRight: "0.4rem",
                    gap: "10rem",
                  }}
                >
                  <Form.Group
                    className={`${styles.formGroup} d-flex justify-content-between`}
                    style={{ fontWeight: "400", flex: "1" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        color:
                          redRequiredFields.includes("idType") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      *ID Type:
                    </Form.Label>
                    <div className={styles.idType}>
                      <Form.Control
                        type="text"
                        value={sellData.idType}
                        defaultValue=""
                        style={{
                          fontWeight: "700",
                          border:
                            redRequiredFields.includes("idType") &&
                            isSubmitButtonClicked
                              ? "1px solid red"
                              : "",
                        }}
                        onClick={() => handleModalShow("type")}
                      />
                      <Button
                        style={{ backgroundColor: "#d1823a", border: "none" }}
                        onClick={() => handleModalShow("type")}
                      >
                        <span style={{ fontSize: "x-large" }}>
                          {" "}
                          <IoMdArrowRoundForward />
                        </span>
                      </Button>
                    </div>
                  </Form.Group>

                  <Form.Group
                    className={`${styles.formGroup} d-flex justify-content-between`}
                    style={{ fontWeight: "400", flex: "1" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        color:
                          redRequiredFields.includes("idState") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                      className="text-nowrap"
                    >
                      {isPassPortSelected.forBuyer
                        ? "ID Country:"
                        : "ID State:"}
                    </Form.Label>
                    <div className={styles.idType}>
                      <Form.Control
                        type="text"
                        value={sellData.idState}
                        defaultValue=""
                        onClick={() => handleModalShow("state")}
                        style={{
                          fontWeight: "700",
                          boxShadow: "0px 3px 5px 1px lightgrey",
                          border:
                            redRequiredFields.includes("idState") &&
                            isSubmitButtonClicked
                              ? "1px solid red"
                              : "",
                        }}
                      />
                      <Button
                        style={{ backgroundColor: "#d1823a", border: "none" }}
                        onClick={() => handleModalShow("state")}
                      >
                        <span style={{ fontSize: "x-large" }}>
                          {" "}
                          <IoMdArrowRoundForward />
                        </span>
                      </Button>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className={styles.formGroup}
                    style={{ fontWeight: "400", flex: "1" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        color:
                          redRequiredFields.includes("idNumber") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      *ID Number:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={sellData.idNumber}
                      onChange={(e) =>
                        handleInputChange("idNumber", e.target.value)
                      }
                      id={styles.lighOranegBackgroundColor}
                      style={{
                        fontWeight: "700",
                        boxShadow: "0px 3px 5px 1px lightgrey",
                        border:
                          redRequiredFields.includes("idNumber") &&
                          isSubmitButtonClicked
                            ? "1px solid red"
                            : "",
                      }}
                    />
                  </Form.Group>
                </div>
                <div
                  className="d-flex"
                  style={{ paddingLeft: "0.4rem", marginTop: "0.5rem" }}
                >
                  <Form.Group
                    className={styles.formGroup}
                    style={{ flex: "0.7", marginRight: "1rem" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        width: "55%",
                        marginBottom: "0.5rem",
                        color:
                          redRequiredFields.includes("address") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      *Address:
                    </Form.Label>

                    <div
                      className={styles.addressInput}
                      style={{
                        // borderRight: "0.8rem solid #fce8cf",
                        backgroundColor: "#fce8cf",
                        padding: "0",
                      }}
                    >
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (buyerAutocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={() =>
                          handlePlaceSelect("buyer", "address")
                        }
                      >
                        <input
                          type="text"
                          className="form-control-plaintext fw-bold"
                          placeholder="Enter your address"
                          value={sellData.address}
                          onChange={(e) =>
                            handleInputChange("address", e.target.value)
                          }
                          name="address"
                          style={{
                            fontWeight: "700",
                            boxShadow: "0px 3px 5px 1px lightgrey",
                            padding: "7px",
                            border:
                              redRequiredFields.includes("address") &&
                              isSubmitButtonClicked
                                ? "1px solid red"
                                : "",
                          }}
                        />
                      </Autocomplete>
                      {/* <span
                        onClick={(e) => {
                          e.preventDefault();
                          handleGMapAPI(false);
                        }}
                        className={styles.locationDetectionIcon}
                      >
                        <MdOutlineMyLocation />
                      </span> */}
                    </div>

                    {/* {addValiError && sellData?.address?.length > 0 && (
                      <span style={{ color: "red" }}>
                        {" "}
                        Please format your address with three commas: "Street,
                        City, State, ZIP"
                      </span>
                    )} */}
                  </Form.Group>

                  <Form.Group
                    className={styles.formGroup}
                    style={{ flex: "0.6", marginRight: "1rem" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        // border : "1px solid red",
                        width: "23%",
                        fontWeight: "400",
                        marginBottom: "0.5rem",
                        color:
                          redRequiredFields.includes("address2") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      Address2:
                    </Form.Label>

                    <div
                      className={styles.addressInput}
                      style={{
                        // borderRight: "0.8rem solid #fce8cf",
                        backgroundColor: "#fce8cf",
                        padding: "0",
                      }}
                    >
                      <input
                        type="text"
                        className={`form-control-plaintext fw-bold `}
                        id={styles.greyBackground}
                        placeholder="Apt#"
                        value={sellData.address2}
                        onChange={(e) =>
                          handleInputChange("address2", e.target.value)
                        }
                        style={{
                          fontWeight: "700",
                          padding: "7px",
                          backgroundColor: "#e8edea",
                          boxShadow: "0px 3px 5px 1px lightgrey",
                          border:
                            redRequiredFields.includes("address2") &&
                            isSubmitButtonClicked
                              ? "1px solid red"
                              : "",
                        }}
                        name="address2"
                      />
                    </div>
                  </Form.Group>
                  {/* Frame for city, state, zipcode */}
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-around",
                      boxSizing: "border-box",
                      flex: "1.6",
                    }}
                  >
                    <Form.Group
                      className={styles.formGroup}
                      style={{ flex: "0.4" }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: "400",
                          // border : "1px solid red",
                          width: "23%",
                          marginBottom: "0.5rem",

                          color:
                            redRequiredFields.includes("city") &&
                            isSubmitButtonClicked
                              ? "red"
                              : "",
                        }}
                      >
                        *City:
                      </Form.Label>

                      <div
                        className={styles.addressInput}
                        style={{
                          borderRight: "0.8rem solid #fce8cf",
                          backgroundColor: "#fce8cf",
                          padding: "0",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control-plaintext fw-bold"
                          placeholder="City"
                          value={sellData.city}
                          id={styles.greyBackground}
                          onChange={(e) =>
                            handleInputChange("city", e.target.value)
                          }
                          style={{
                            fontWeight: "700",
                            // padding: "7px",
                            // padding: "5px",
                            paddingBottom: "0.6rem",
                            boxShadow: "0px 3px 5px 1px lightgrey",
                            border:
                              redRequiredFields.includes("city") &&
                              isSubmitButtonClicked
                                ? "1px solid red"
                                : "",
                          }}
                          name="city"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group
                      className={styles.formGroup}
                      style={{ flex: "0.3" }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: "400",
                          // border : "1px solid red",
                          width: "23%",
                          padding: "0",
                          marginBottom: "0.5rem",

                          color:
                            redRequiredFields.includes("state") &&
                            isSubmitButtonClicked
                              ? "red"
                              : "",
                        }}
                      >
                        *State:
                      </Form.Label>

                      <div
                        className={styles.addressInput}
                        style={{
                          borderRight: "0.8rem solid #fce8cf",
                          backgroundColor: "#fce8cf",
                          padding: "0",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control-plaintext fw-bold"
                          placeholder="State"
                          value={sellData.state}
                          id={styles.greyBackground}
                          onChange={(e) =>
                            handleInputChange("state", e.target.value)
                          }
                          style={{
                            fontWeight: "700",
                            // padding: "7px",
                            paddingBottom: "0.6rem",
                            boxShadow: "0px 3px 5px 1px lightgrey",

                            border:
                              redRequiredFields.includes("state") &&
                              isSubmitButtonClicked
                                ? "1px solid red"
                                : "",
                          }}
                          name="state"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group
                      className={styles.formGroup}
                      style={{ flex: "0.3", marginRight: "1rem" }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: "400",
                          // border : "1px solid red",
                          width: "23%",
                          fontWeight: "400",
                          marginBottom: "0.6rem",
                          color:
                            redRequiredFields.includes("zipCode") &&
                            isSubmitButtonClicked
                              ? "red"
                              : "",
                        }}
                      >
                        *ZipCode:
                      </Form.Label>

                      <div
                        className={styles.addressInput}
                        style={{
                          backgroundColor: "#fce8cf",
                          padding: 0,
                          marginRight: "1rem",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control-plaintext fw-bold"
                          placeholder="Zip-Code"
                          id={styles.greyBackground}
                          value={sellData.zipCode}
                          onChange={(e) =>
                            handleInputChange("zipCode", e.target.value)
                          }
                          style={{
                            fontWeight: "700",
                            // padding: "7px",
                            paddingBottom: "0.5rem",
                            boxShadow: "0px 3px 5px 1px lightgrey",
                            border:
                              redRequiredFields.includes("zipCode") &&
                              isSubmitButtonClicked
                                ? "1px solid red"
                                : "",
                          }}
                          name="zipCode"
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <Form.Group
                    className={styles.formGroup}
                    style={{ flex: "0.6" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        width: "23%",
                        width: "55%",
                        // marginBottom: "0.5rem",
                        color:
                          redRequiredFields.includes("county") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      *County:
                    </Form.Label>

                    <div className={styles.addressInput}>
                      <input
                        type="text"
                        className="form-control-plaintext fw-bold"
                        placeholder="County"
                        value={sellData.county}
                        onChange={(e) =>
                          handleInputChange("county", e.target.value)
                        }
                        style={{
                          fontWeight: "700",
                          // padding: "7px",
                          paddingBottom: "0.5rem",
                          boxShadow: "0px 3px 5px 1px lightgrey",
                          border:
                            redRequiredFields.includes("county") &&
                            isSubmitButtonClicked
                              ? "1px solid red"
                              : "",
                        }}
                        name="county"
                      />
                    </div>
                  </Form.Group>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    paddingLeft: "0.4rem",
                    paddingRight: "0.4rem",
                    gap: "5rem",
                  }}
                >
                  <Form.Group
                    className={styles.formGroup}
                    // style={{ fontWeight: "400", flex: "1" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        width: "23%",

                        color:
                          redRequiredFields.includes("phoneNumber") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      *Phone:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formattedBuyerPhoneNumber}
                      maxLength={14}
                      style={{
                        fontWeight: "700",
                        padding: "7px",
                        boxShadow: "0px 3px 5px 1px lightgrey",
                        border:
                          redRequiredFields.includes("phoneNumber") &&
                          isSubmitButtonClicked
                            ? "1px solid red"
                            : "",
                      }}
                      id={styles.lighOranegBackgroundColor}
                      onChange={(e) => {
                        const unformattedPhoneNumber = e.target.value.replace(
                          /\D+/g,
                          ""
                        );
                        handleInputChange(
                          "phoneNumber",
                          unformattedPhoneNumber
                        );
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className={styles.formGroup}
                    // style={{ fontWeight: "400", flex: "1" }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "400",

                        color:
                          redRequiredFields.includes("email") &&
                          isSubmitButtonClicked
                            ? "red"
                            : "",
                      }}
                    >
                      *Email:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      style={{
                        fontWeight: "700",
                        padding: "7px",
                        boxShadow: "0px 3px 5px 1px lightgrey",
                        border:
                          redRequiredFields.includes("email") &&
                          isSubmitButtonClicked
                            ? "1px solid red"
                            : "",
                      }}
                      id={styles.lighOranegBackgroundColor}
                      value={sellData.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
              </Col>

              {showComBuyerTab ? (
                <Col className="border mt-2 pt-3 pb-2">
                  <p
                    style={{
                      fontSize: "x-large",
                      backgroundColor: "lightgrey",
                      fontWeight: "700",
                      display: "flex",
                      mixBlendMode: "multiply",
                      justifyContent: "center",
                    }}
                  >
                    <span>Co-Buyer</span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingRight: "1rem",

                      marginBottom: "0",
                    }}
                  >
                    <label
                      className="checkbox-container text-nowrap"
                      style={{
                        float: "right",
                        color: "#e8a262",
                        marginRight: "28px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isEntity.coBuyer}
                        onChange={(e) => handleEntityCheckbox(e, "coBuyer")}
                        style={{ marginRight: "10px" }}
                      />
                      Co-Buyer Entity
                    </label>
                    <img
                      src={resetIcon}
                      alt="reset"
                      height={30}
                      onClick={handleResetCoBuyerData}
                    />
                  </p>
                  <div className="d-flex" style={{ gap: "9rem" }}>
                    {isEntity.coBuyer ? (
                      <Form.Group
                        className={`${styles.formGroup}`}
                        style={{ fontWeight: "400", flex: "1" }}
                      >
                        <Form.Label style={{ fontWeight: "400" }}>
                          Business Name:
                        </Form.Label>
                        <div className={styles.inlineFields}>
                          <Form.Control
                            type="text"
                            value={sellCoBuyerData.businessName}
                            style={{
                              fontWeight: "700",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                            }}
                            onChange={(e) =>
                              handleCoBuyerInputChange(
                                "businessName",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Form.Group>
                    ) : (
                      <>
                        <Form.Group
                          className={`${styles.formGroup}`}
                          style={{ fontWeight: "400", flex: "1" }}
                        >
                          <Form.Label style={{ fontWeight: "400" }}>
                            First Name:
                          </Form.Label>
                          <div className={styles.inlineFields}>
                            <Form.Control
                              type="text"
                              value={sellCoBuyerData.firstName}
                              style={{ fontWeight: "700" }}
                              onChange={(e) =>
                                handleCoBuyerInputChange(
                                  "firstName",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </Form.Group>
                        <Form.Group
                          className={`${styles.formGroup}`}
                          style={{ fontWeight: "400", flex: "1" }}
                        >
                          <Form.Label style={{ fontWeight: "400" }}>
                            Middle Name:
                          </Form.Label>
                          <div className={styles.inlineFields}>
                            <Form.Control
                              type="text"
                              style={{
                                fontWeight: "700",
                                boxShadow: "0px 3px 5px 1px lightgrey",
                              }}
                              value={sellCoBuyerData.middleName}
                              onChange={(e) =>
                                handleCoBuyerInputChange(
                                  "middleName",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </Form.Group>
                        <Form.Group
                          className={`${styles.formGroup}`}
                          style={{ fontWeight: "400", flex: "1" }}
                        >
                          <Form.Label style={{ fontWeight: "400" }}>
                            Last Name:
                          </Form.Label>
                          <div className={styles.inlineFields}>
                            <Form.Control
                              type="text"
                              value={sellCoBuyerData.lastName}
                              style={{
                                fontWeight: "700",
                                boxShadow: "0px 3px 5px 1px lightgrey",
                              }}
                              onChange={(e) =>
                                handleCoBuyerInputChange(
                                  "lastName",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </Form.Group>
                      </>
                    )}
                  </div>
                  <div
                    className="d-flex "
                    style={{
                      paddingLeft: "0.4rem",
                      paddingRight: "0.4rem",
                      gap: "10rem",
                    }}
                  >
                    <Form.Group
                      className={`${styles.formGroup} d-flex justify-content-between`}
                      style={{ fontWeight: "400", flex: "1" }}
                    >
                      <Form.Label style={{ fontWeight: "400" }}>
                        ID Type:
                      </Form.Label>
                      <div className={styles.idType}>
                        <Form.Control
                          type="text"
                          style={{
                            fontWeight: "700",
                            boxShadow: "0px 3px 5px 1px lightgrey",
                          }}
                          value={sellCoBuyerData.idType}
                          onClick={() => handleModalShow("type", true)}
                        />
                        <Button
                          // variant="secondary"

                          style={{ backgroundColor: "#d1823a", border: "none" }}
                          onClick={() => handleModalShow("type", true)}
                        >
                          {/* &#10140; */}
                          <span style={{ fontSize: "x-large" }}>
                            {" "}
                            <IoMdArrowRoundForward />
                          </span>
                        </Button>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className={`${styles.formGroup} d-flex justify-content-between`}
                      style={{ fontWeight: "400", flex: "1" }}
                    >
                      <Form.Label
                        style={{ fontWeight: "400" }}
                        className="text-nowrap"
                      >
                        {isPassPortSelected.forCoBuyer
                          ? "ID Country:"
                          : "ID State:"}
                      </Form.Label>
                      <div className={styles.idType}>
                        <Form.Control
                          type="text"
                          value={sellCoBuyerData.idState}
                          style={{ fontWeight: "700" }}
                          onClick={() => handleModalShow("state", true)}
                        />
                        <Button
                          // variant="secondary"

                          style={{ backgroundColor: "#d1823a", border: "none" }}
                          onClick={() => handleModalShow("state", true)}
                        >
                          <span style={{ fontSize: "x-large" }}>
                            {" "}
                            <IoMdArrowRoundForward />
                          </span>
                        </Button>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className={styles.formGroup}
                      style={{ fontWeight: "400", flex: "1" }}
                    >
                      <Form.Label style={{ fontWeight: "400" }}>
                        ID Number:
                      </Form.Label>
                      <Form.Control
                        style={{ fontWeight: "700" }}
                        type="text"
                        value={sellCoBuyerData.idNumber}
                        id={styles.lighOranegBackgroundColor}
                        onChange={(e) =>
                          handleCoBuyerInputChange("idNumber", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  {/* <p
                    style={{
                      paddingRight: "6rem",
                      marginTop: "1rem",
                      marginBottom: "0.3rem",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name=""
                      id="sameAddress"
                      checked={sameAddress}
                      onChange={() => setSameAddress(!sameAddress)}
                      style={{ marginRight: "0.5rem" }}
                    />
                    <label htmlFor="sameAddress">Address same as above</label>
                  </p> */}
                  <div
                    className="d-flex mt-4"
                    style={{ paddingLeft: "0.4rem" }}
                  >
                    <Form.Group
                      className={styles.formGroup}
                      style={{ flex: "0.7", marginRight: "1rem" }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: "400",
                          // border: "1px solid black",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <span
                          style={{
                            flex: "1",
                          }}
                        >
                          Address:
                        </span>{" "}
                        <span
                          style={{
                            // paddingLeft: "1rem",
                            // paddingRight: "10rem",

                            fontWeight: "600",
                            display: "flex",
                            justifyContent: "center",
                            flex: "1.9",
                          }}
                        >
                          <input
                            type="checkbox"
                            name=""
                            id="sameAddress"
                            checked={sameAddress}
                            onChange={() => setSameAddress(!sameAddress)}
                            // style={{ marginRight: "0.5rem" }}
                          />
                          <label
                            htmlFor="sameAddress"
                            style={{
                              width: "70%",
                              fontWeight: "600",
                              fontSize: "10px",
                            }}
                          >
                            Address same as above
                          </label>
                        </span>
                      </Form.Label>

                      <div
                        className={styles.addressInput}
                        style={{
                          // borderRight: "0.8rem solid #fce8cf",
                          backgroundColor: "#fce8cf",
                          padding: "0",
                        }}
                      >
                        <Autocomplete
                          onLoad={(autocomplete) =>
                            (coBuyerAutocompleteRef.current = autocomplete)
                          }
                          onPlaceChanged={() =>
                            handlePlaceSelect("coBuyer", "address")
                          }
                        >
                          <input
                            type="text"
                            className="form-control-plaintext fw-bold"
                            placeholder="Enter your address"
                            value={sellCoBuyerData.address}
                            style={{
                              // padding: "7px",
                              paddingBottom: "0.6rem",
                              borderRight: "0.8rem solid #fce8cf",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                            }}
                            id={styles.lighOranegBackgroundColor}
                            onChange={(e) =>
                              handleCoBuyerInputChange(
                                "address",
                                e.target.value
                              )
                            }
                            name="address"
                          />
                        </Autocomplete>
                        {/* <span
                          onClick={() => handleGMapAPI(true)}
                          className={styles.locationDetectionIcon}
                        >
                          <MdOutlineMyLocation />
                        </span> */}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className={styles.formGroup}
                      style={{ flex: "0.6", marginRight: "1rem" }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: "400",
                          width: "20%",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Address2:
                      </Form.Label>

                      <div
                        className={styles.addressInput}
                        style={{
                          // borderRight: "0.8rem solid #fce8cf",
                          backgroundColor: "#fce8cf",
                          padding: "0",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control-plaintext fw-bold"
                          placeholder="Apt#"
                          id={styles.greyBackground}
                          value={sellCoBuyerData.address2}
                          style={{
                            paddingBottom: "0.6rem",
                            boxShadow: "0px 3px 5px 1px lightgrey",
                          }}
                          onChange={(e) =>
                            handleCoBuyerInputChange("address2", e.target.value)
                          }
                          name="address2"
                        />
                      </div>
                    </Form.Group>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-around",
                        boxSizing: "border-box",
                        flex: "1.6",
                      }}
                    >
                      <Form.Group
                        className={styles.formGroup}
                        style={{ flex: "0.4" }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "400",
                            width: "20%",
                            marginBottom: "0.5rem",
                          }}
                        >
                          City:
                        </Form.Label>

                        <div
                          className={styles.addressInput}
                          style={{
                            borderRight: "0.8rem solid #fce8cf",
                            backgroundColor: "#fce8cf",
                            padding: "0",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control-plaintext fw-bold"
                            placeholder="City"
                            value={sellCoBuyerData.city}
                            id={styles.greyBackground}
                            style={{
                              paddingBottom: "0.6rem",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                            }}
                            onChange={(e) =>
                              handleCoBuyerInputChange("city", e.target.value)
                            }
                            name="city"
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className={styles.formGroup}
                        style={{ flex: "0.3" }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "400",
                            width: "20%",
                            marginBottom: "0.5rem",
                          }}
                        >
                          State:
                        </Form.Label>

                        <div
                          className={styles.addressInput}
                          style={{
                            borderRight: "0.8rem solid #fce8cf",
                            backgroundColor: "#fce8cf",
                            padding: "0",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control-plaintext fw-bold"
                            placeholder="State"
                            value={sellCoBuyerData.state}
                            id={styles.greyBackground}
                            style={{
                              padding: "7px",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                            }}
                            onChange={(e) =>
                              handleCoBuyerInputChange("state", e.target.value)
                            }
                            name="state"
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className={styles.formGroup}
                        style={{ flex: "0.3" }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "400",
                            width: "20%",
                            marginBottom: "0.5rem",
                          }}
                        >
                          ZipCode:
                        </Form.Label>

                        <div
                          className={styles.addressInput}
                          style={{
                            backgroundColor: "#fce8cf",
                            padding: "0",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control-plaintext fw-bold"
                            placeholder="ZipCode"
                            id={styles.greyBackground}
                            value={sellCoBuyerData.zipCode}
                            style={{
                              paddingBottom: "0.6rem",
                              marginRight: "1rem",
                              boxShadow: "0px 3px 5px 1px lightgrey",
                            }}
                            onChange={(e) =>
                              handleCoBuyerInputChange(
                                "zipCode",
                                e.target.value
                              )
                            }
                            name="zipCode"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <Form.Group
                      className={styles.formGroup}
                      style={{ flex: "0.6" }}
                    >
                      <Form.Label style={{ fontWeight: "400", width: "20%" }}>
                        County:
                      </Form.Label>

                      <div className={styles.addressInput}>
                        <input
                          type="text"
                          className="form-control-plaintext fw-bold"
                          placeholder="County"
                          value={sellCoBuyerData.county}
                          style={{
                            padding: "7px",
                            boxShadow: "0px 3px 5px 1px lightgrey",
                          }}
                          onChange={(e) =>
                            handleCoBuyerInputChange("county", e.target.value)
                          }
                          name="county"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      paddingLeft: "0.4rem",
                      paddingRight: "0.4rem",
                      gap: "5rem",
                    }}
                  >
                    <Form.Group
                      className={styles.formGroup}
                      // style={{ fontWeight: "400", flex: "1" }}
                    >
                      <Form.Label style={{ fontWeight: "400" }}>
                        Phone:
                      </Form.Label>
                      {/* <Form.Control
                        type="text"
                        style={{ fontWeight: "700" }}
                        // defaultValue="(469) - 456 6560"
                        value={sellCoBuyerData.phoneNumber}
                        onChange={(e) =>
                          handleCoBuyerInputChange(
                            "phoneNumber",
                            e.target.value
                          )
                        }
                      /> */}
                      <Form.Control
                        type="text"
                        value={formattedCoBuyerPhoneNumber}
                        maxLength={14}
                        style={{
                          fontWeight: "700",
                          boxShadow: "0px 3px 5px 1px lightgrey",
                        }}
                        id={styles.lighOranegBackgroundColor}
                        onChange={(e) => {
                          const unformattedPhoneNumber = e.target.value.replace(
                            /\D+/g,
                            ""
                          );
                          handleCoBuyerInputChange(
                            "phoneNumber",
                            unformattedPhoneNumber
                          );
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className={styles.formGroup}
                      // style={{ fontWeight: "400", flex: "1" }}
                    >
                      <Form.Label style={{ fontWeight: "400" }}>
                        Email:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        style={{
                          fontWeight: "700",
                          boxShadow: "0px 3px 5px 1px lightgrey",
                        }}
                        // defaultValue="Dallas.Dallas@gmail.com"
                        value={sellCoBuyerData.email}
                        onChange={(e) =>
                          handleCoBuyerInputChange("email", e.target.value)
                        }
                        id={styles.lighOranegBackgroundColor}
                      />
                    </Form.Group>
                  </div>
                </Col>
              ) : null}
            </Row>
          </LoadScript>
        </Form>

        <Modal show={modalState.visible} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState.type === "type"
                ? "Select ID Type"
                : "Select ID State"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
            {modalState.type === "type" && (
              <>
                <Form.Check
                  type="radio"
                  label="U.S Driver License"
                  name="documentType"
                  id="driverLicense"
                  onChange={
                    modalState.forCoBuyer
                      ? () => handleCoBuyerSelectType("U.S Driver License")
                      : () => handleSelectType("U.S Driver License")
                  }
                />
                <Form.Check
                  type="radio"
                  label="U.S. State ID"
                  name="documentType"
                  id="stateID"
                  // onChange={() => handleSelectType("U.S. State ID")}
                  onChange={
                    modalState.forCoBuyer
                      ? () => handleCoBuyerSelectType("U.S. State ID")
                      : () => handleSelectType("U.S Driver License")
                  }
                />
                <Form.Check
                  type="radio"
                  label="U.S. Citizenship &Immigration Services/DOJ ID"
                  name="documentType"
                  id="Citizenship"
                  // onChange={() =>
                  //   handleSelectType(
                  //     "U.S. Citizenship &Immigration Services/DOJ ID"
                  //   )
                  // }
                  onChange={
                    modalState.forCoBuyer
                      ? () =>
                          handleCoBuyerSelectType(
                            "U.S. Citizenship &Immigration Services/DOJ ID"
                          )
                      : () =>
                          handleSelectType(
                            "U.S. Citizenship &Immigration Services/DOJ ID"
                          )
                  }
                />
                <Form.Check
                  type="radio"
                  label="Passport"
                  name="documentType"
                  id="passport"
                  // onChange={() => handleSelectType("Passport")}
                  onChange={
                    modalState.forCoBuyer
                      ? () => handleCoBuyerSelectType("Passport")
                      : () => handleSelectType("Passport")
                  }
                  // onClick={() =>{
                  //   setShowPassportModal(true);
                  //   setIsPassportModalForCoBuyer(modalState.forCoBuyer ? true : false)

                  // } }
                />
                <Form.Check
                  type="radio"
                  label="NATO ID"
                  name="documentType"
                  id="natoId"
                  // onChange={() => handleSelectType("NATO ID")}
                  onChange={
                    modalState.forCoBuyer
                      ? () => handleCoBuyerSelectType("NATO ID")
                      : () => handleSelectType("NATO ID")
                  }
                />
                <Form.Check
                  type="radio"
                  label="U.S. Military ID"
                  name="documentType"
                  id="militaryId"
                  // onChange={() => handleSelectType("U.S. Military ID")}
                  onChange={
                    modalState.forCoBuyer
                      ? () => handleCoBuyerSelectType("U.S. Military ID")
                      : () => handleSelectType("U.S. Military ID")
                  }
                />
                <Form.Check
                  type="radio"
                  label="U.S. Dept. of State ID"
                  name="documentType"
                  id="deptStateId"
                  // onChange={() => handleSelectType("U.S. Dept. of State ID")}
                  onChange={
                    modalState.forCoBuyer
                      ? () => handleCoBuyerSelectType("U.S. Dept. of State ID")
                      : () => handleSelectType("U.S. Dept. of State ID")
                  }
                />
                <Form.Check
                  type="radio"
                  label="U.S. Dept. of Homeland Security ID"
                  name="documentType"
                  id="deptHomelandSecurityId"
                  // onChange={() =>
                  //   handleSelectType("U.S. Dept. of Homeland Security ID")
                  // }
                  onChange={
                    modalState.forCoBuyer
                      ? () =>
                          handleCoBuyerSelectType(
                            "U.S. Dept. of Homeland Security ID"
                          )
                      : () =>
                          handleSelectType("U.S. Dept. of Homeland Security ID")
                  }
                />
                <Form.Check
                  type="radio"
                  label="Other Military of Forces Photo ID"
                  name="documentType"
                  id="otherMilitaryForcesPhotoId"
                  // onChange={() =>
                  //   handleSelectType("Other Military of Forces Photo ID")
                  // }
                  onChange={
                    modalState.forCoBuyer
                      ? () =>
                          handleCoBuyerSelectType(
                            "Other Military of Forces Photo ID"
                          )
                      : () =>
                          handleSelectType("Other Military of Forces Photo ID")
                  }
                />
                {!modalState.forCoBuyer && isEntity.buyer && (
                  <Form.Check
                    type="radio"
                    label="EIN"
                    name="documentType"
                    id="ein"
                    // onChange={() =>
                    //   handleSelectType("Other Military of Forces Photo ID")
                    // }
                    onChange={() => handleSelectType("EIN")}
                  />
                )}
                {modalState.forCoBuyer && isEntity.coBuyer && (
                  <Form.Check
                    type="radio"
                    label="EIN"
                    name="documentType"
                    id="ein"
                    onChange={() => handleCoBuyerSelectType("EIN")}
                  />
                )}
              </>
            )}

            {modalState.type === "state" && (
              <ReactFocusLock>
                <div
                  tabIndex={0}
                  ref={listRef}
                  onKeyDown={handleKeyDown}
                  // style={{ border: "1px solid black" }}
                >
                  {(
                    modalState.forCoBuyer
                      ? isPassPortSelected.forCoBuyer
                      : isPassPortSelected.forBuyer
                  )
                    ? countryNames.map((state) => (
                        <Form.Check
                          key={state}
                          type="radio"
                          label={state}
                          data-value={state}
                          name="documentState"
                          checked={idCountry === state}
                          onClick={
                            modalState.forCoBuyer
                              ? () => handleSelectCoBuyerState(state)
                              : () => handleSelectState(state)
                          }
                          id={state}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              state === idCountry ? "#e0e0e0" : "transparent",
                          }}
                          onChange={
                            modalState.forCoBuyer
                              ? () => handleSelectCoBuyerState(state)
                              : () => handleSelectState(state)
                          }
                        />
                      ))
                    : usaStates.map((state) => (
                        <Form.Check
                          key={state.abbreviation}
                          type="radio"
                          label={state.name}
                          data-value={state.name}
                          name="documentState"
                          onClick={
                            modalState.forCoBuyer
                              ? () => handleSelectCoBuyerState(state.name)
                              : () => handleSelectState(state.name)
                          }
                          checked={idState === state.name}
                          id={state.abbreviation}
                          onChange={
                            modalState.forCoBuyer
                              ? () => handleSelectCoBuyerState(state.name)
                              : () => handleSelectState(state.name)
                          }
                        />
                      ))}
                </div>
              </ReactFocusLock>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default BuyerInfo;
