import React from 'react'
import { Modal } from 'react-bootstrap'

const PaymentDueData = ({ show, handleClose,PaymentData}) => {

    console.log("PaymentData",PaymentData);
  return (
    <Modal show={show} size="xl" onHide={handleClose}  dialogClassName="modal-90w">
    <Modal.Header closeButton>
      <div>
        <Modal.Title>Payment Due Data</Modal.Title>
      </div>
    </Modal.Header>
    <Modal.Body>
      Payment Due : $ {PaymentData?.sellPriceFees?.balanceDue}
    </Modal.Body>
    <Modal.Footer
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <button
        className="btn btn-secondary"
        style={{ width: "150px" }}
        onClick={handleSubmit}
        disabled={loading}
      >
       {loading ? <div className="spinner"></div> : 'Submit'}
      </button> */}
    </Modal.Footer>
  </Modal>
  )
}

export default PaymentDueData